import React from "react";

export interface LoadingRef {
    show: (modal: boolean) => void;
    hide: () => void;
}

export default React.forwardRef<LoadingRef, {}>(function Loading(_, ref) {
    let [status, setStatus] = React.useState(false);
    let [modal, setModal] = React.useState(false);
    React.useImperativeHandle(ref, () => ({
        show: (modal) => {
            setModal(modal);
            setStatus(true);
        },
        hide: () => setStatus(false)
    }), [status, modal]);
    return status ? (
        <>
            {modal ? (<div className="loading-mask" />) : null}
            <div className="loading global">
                <span className="fa fa-spin fa-spinner text-white" />
            </div>
        </>
    ) : null;
});
