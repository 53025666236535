import React from "react";
import { useSearchParams } from "react-router-dom";
import Grid, { Actions, Columns, GridRef } from "../../component/Grid";
import useLocale from "../../util/i18n";
import { useCrumbs } from "../../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import { delMaintenance, getMaintenance, MaintenanceData, saveMaintenance } from "../../api/warehouse";
import FormatCurrency, { CurrencyContext, formatCurrency } from "../../util/currency";

export default function MaintenanceGrid() {
    let [query, setQuery] = useSearchParams();
    let [__, lang] = useLocale();
    let [currency] = React.useContext(CurrencyContext);
    const ref = React.useRef<GridRef>(null);
    const doSave = React.useCallback((data: MaintenanceData) => {
        return new Promise<string>((resolve, reject) => {
            if (!data.id) {
                data.warehouse_id ??= parseInt(query.get('filter[warehouse_id]') || '0');
                data.name ??= {};
                data.price ??= 1;
            }
            saveMaintenance(data).then(resolve, reject);
        });
    }, [query]);
    let columns = React.useMemo<Columns>(() => ({
        id: {
            label: 'ID',
            className: 'w-1 text-center text-nowrap'
        },
        name: {
            label: __('Name'),
            format: value => __(value),
            editable: (item, row, e) => {
                e.stopPropagation();
                if (item.name?.default?.toString() === e.target.innerText) {
                    return;
                }
                let data = { ...item, name: { default: e.target.innerText } };
                doSave(data).then(() => {
                    row.redraw(data);
                    if (!data.id) {
                        ref.current?.reload();
                    }
                }, () => { });
            }
        },
        price: {
            label: __('Price'),
            className: 'w-1 text-center text-nowrap',
            format: value => value ? (<FormatCurrency>{value}</FormatCurrency>) : '0',
            editable: (item, row, e) => {
                e.stopPropagation();
                if (item.price?.toString() === e.target.innerText) {
                    e.target.innerText = formatCurrency(parseFloat(e.target.innerText), lang, currency);
                    return;
                }
                let data = { ...item, price: e.target.innerText };
                doSave(data).then(() => {
                    row.redraw(data);
                    if (!data.id) {
                        ref.current?.reload();
                    } else {
                        e.target.innerText = formatCurrency(parseFloat(e.target.innerText), lang, currency);
                    }
                }, () => {
                    e.target.innerText = formatCurrency(parseFloat(e.target.innerText), lang, currency);
                });
            }
        },
        status: {
            label: __('Status'),
            className: 'w-1 text-center text-nowrap',
            type: 'select',
            options: [
                { value: '1', label: __('Enabled') }, { value: '0', label: __('Disabled') }
            ],
            format: (value: number) => value ? __('Enabled') : __('Disabled')
        }
    }), [__, lang, currency, doSave]);
    let actions = React.useMemo<Actions>(() => ([
        {
            label: __('Enable'),
            condition: (item: Record<string, any>) => item.status?.toString() === '0',
            onClick: (item: MaintenanceData, row) => {
                saveMaintenance({ id: item.id, status: 1 });
                row.redraw({ ...item, status: 1 });
            }
        },
        {
            label: __('Disable'),
            condition: (item: Record<string, any>) => item.status?.toString() === '1',
            onClick: (item: MaintenanceData, row) => {
                saveMaintenance({ id: item.id, status: 0 });
                row.redraw({ ...item, status: 0 });
            }
        },
        {
            label: __('Delete'),
            condition: (item: Record<string, any>) => Boolean(item.id),
            onClick: (item: MaintenanceData, row) => {
                delMaintenance({ id: item.id });
                row.remove();
            }
        }
    ]), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        return getMaintenance(params);
    });
    const onNewUrl = React.useRef((data: Array<Record<string, any>>, setData: React.Dispatch<React.SetStateAction<Array<Record<string, any>>>>) => {
        setData([{}, ...data]);
    });
    let [_, setCrumbs] = useCrumbs();
    React.useEffect(() => {
        setCrumbs(['Maintenance']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Maintenance')}</title>
            </Helmet>
            <Grid ref={ref} actions={actions} columns={columns} query={query} setQuery={setQuery} doLoad={doLoad.current} newUrl={onNewUrl.current} />
        </>
    );
}
