import React from "react";
import Edit, { Columns } from "../../../component/Edit";
import useLocale from "../../../util/i18n";
import { TemplateData, getTemplate, saveTemplate, deleteTemplate } from "../../../api/email";
import Toast from "../../../component/toast";
import { useCrumbs } from "../../../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import { CancelablePromise } from "../../../util/client";

export default function EmailTmplEdit() {
    let [__] = useLocale();
    let [_, setCrumbs] = useCrumbs();
    let columns = React.useMemo<Columns>(() => ({
        [__('General')]: [
            {
                name: 'id',
                type: 'hidden'
            },
            {
                name: 'code',
                label: __('Code'),
                required: true
            },
            {
                name: 'language',
                label: __('Language'),
                type: 'language'
            },
            {
                name: 'subject',
                label: __('Subject')
            },
            {
                name: 'status',
                label: __('Status'),
                type: 'select',
                required: true,
                options: [
                    { value: '1', label: __('Enabled') },
                    { value: '0', label: __('Disabled') }
                ]
            },
            {
                name: 'css',
                label: 'CSS',
                type: 'textarea'
            },
            {
                name: 'content',
                label: __('Content'),
                type: 'wysiwyg'
            }
        ]
    }), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        let p = getTemplate({ filter: { id: params.id }, limit: 1 });
        return new CancelablePromise<TemplateData>((resolve, reject) => p.then(response => {
            if (typeof response !== 'string' && response.data.length) {
                resolve(response.data[0]);
            } else {
                reject(response);
            }
        }), p.doCancel);
    });
    const doSave = React.useRef((data: FormData) => {
        saveTemplate(data).then(response => Toast.show(response, 'success'));
    });
    const doDelete = React.useRef((data: Record<string, any>) => {
        if (window.confirm(__('Are you sure to delete this?'))) {
            deleteTemplate(data.id).then(response => Toast.show(response, 'success'));
        }
    });
    React.useEffect(() => {
        setCrumbs([{ label: 'Email Template', link: '/email/template' }, 'Edit']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Edit %s').replace('%s', __('Email Template'))}</title>
            </Helmet>
            <Edit columns={columns} doLoad={doLoad.current} doSave={doSave.current} doDelete={doDelete.current} />
        </>
    );
}
