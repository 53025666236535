import React from "react";
import { useSearchParams } from "react-router-dom";
import Grid, { Columns } from "../../component/Grid";
import useLocale from "../../util/i18n";
import { TemplateData, deleteTemplate, getTemplate } from "../../api/email";
import { useCrumbs } from "../../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import Toast from "../../component/toast";

export default function EmailQueue() {
    let [query, setQuery] = useSearchParams();
    let [__, lang] = useLocale();
    let columns = React.useMemo<Columns>(() => ({
        template_id: {
            label: __('Template'),
            format: (value, item) => item.subject
        },
        rcpt: {
            label: __('Recipient'),
            format: (value: Array<string>) => (<>{value.map((email, i) => (<div key={email + i}>{email}</div>))}</>)
        },
        status: {
            label: __('Status'),
            className: 'w-1 text-center text-nowrap',
            filterable: 'status',
            type: 'select',
            options: [
                { value: '1', label: __('Sent') }, { value: '0', label: __('Queue up') }
            ],
            format: (value: number) => value > 0 ? __('Sent') : (value < 0 ? __('Canceled') : __('Queue up'))
        },
        scheduled_at: {
            label: __('Scheduled at'),
            className: 'w-1 text-center text-nowrap',
            format: value => Intl.DateTimeFormat(lang, { dateStyle: 'medium', timeStyle: 'short' }).format(value)
        }
    }), [__]);
    let actions = React.useMemo(() => ([
        {
            label: __('Cancel'),
            onClick: (item: TemplateData) => {
                if (window.confirm(__('Are you sure to cancel this schedule?'))) {
                    deleteTemplate(item.id).then(response => Toast.show(response));
                }
            }
        }
    ]), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        return getTemplate(params);
    });
    let [_, setCrumbs] = useCrumbs();
    React.useEffect(() => {
        setCrumbs(['Email Queue']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Email Queue')}</title>
            </Helmet>
            <Grid columns={columns} actions={actions} query={query} setQuery={setQuery} doLoad={doLoad.current} />
        </>
    );
}
