import React from "react";
import { BillData, getInventoryBill, InventoryData, WarehouseData } from "../api/warehouse";
import FormatCurrency from "../util/currency";
import useLocale from "../util/i18n";
import { MDBBtn } from "mdb-react-ui-kit";

type BillProps = {
    className?: string;
    onDismiss?: (e?: React.MouseEvent<Element>) => void;
} & ({
    sku: string;
    warehouseId?: number;
} | {
    sku: undefined;
    warehouse?: WarehouseData;
    data?: InventoryData;
    bill?: BillData;
});

export default function Bill(props: BillProps) {
    let [warehouse, setWarehouse] = React.useState<WarehouseData | null>(null);
    let [inventory, setInventory] = React.useState<InventoryData | null>(null);
    let [bill, setBill] = React.useState<BillData | null>(null);
    let [__] = useLocale();
    let title = React.useMemo(() => {
        let result: Array<string> = [];
        if (inventory?.info?.title || inventory?.info?.brand || inventory?.info?.model) {
            result.push(inventory?.info?.title || inventory?.info?.brand || inventory?.info?.model);
        }
        inventory?.info?.plate && result.push(inventory?.info?.plate);
        return result.join(' ');
    }, [inventory]);
    let keys = React.useMemo(() => {
        let result: Array<string> = [];
        for (let i in bill) {
            if (!['Total', 'Time', 'Finished at', 'Freight', 'Handover'].includes(i)) {
                result.push(i);
            }
        }
        return result;
    }, [bill]);
    React.useEffect(() => {
        if (typeof props.sku === 'string') {
            getInventoryBill({ sku: props.sku }).then(response => {
                if (typeof response !== 'string') {
                    setWarehouse(response.warehouse);
                    setInventory(response.data);
                    setBill(response.bill);
                } else {
                    props.onDismiss?.();
                }
            }, () => props.onDismiss?.());
        } else {
            props.warehouse && setWarehouse(props.warehouse);
            props.data && setInventory(props.data);
            props.bill && setBill(props.bill);
        }
    }, [props]);
    return (
        <>
            <div className={'bill ' + (props.className || '')}>
                {inventory ? (
                    <header className="bill-header">
                        <h3 className="title">{title}</h3>
                        <h4 className="vin">{inventory.sku}</h4>
                        {warehouse ? (
                            <>
                                {warehouse.phone ? (
                                    <a href={'tel:' + warehouse.phone} target="_blank" rel="noreferrer" className="call"><span className="fa fa-fw fa-phone" /></a>
                                ) : null}
                                <div className="locate">
                                    <span className="fa fa-fw fa-location-dot" />
                                    {warehouse.lat && warehouse.lng ? (
                                        <a href={'https://www.google.com/maps/@' + warehouse.lat + ',' + warehouse.lng + ',17z'} target="_blank" rel="noreferrer">{warehouse.address}</a>
                                    ) : (
                                        <span>{warehouse.address}</span>
                                    )}
                                </div>
                            </>
                        ) : null}
                    </header>
                ) : null}
                {bill ? (
                    <table className="bill-list">
                        <tbody>
                            {keys.map((key, i) => (
                                <tr key={key + i}>
                                    <th className="pe-3">{key}</th>
                                    <td><FormatCurrency>{bill[key]}</FormatCurrency></td>
                                </tr>
                            ))}
                            <tr>
                                <th className="pe-3">{__('Freight')}</th>
                                <td><FormatCurrency>{bill.Freight}</FormatCurrency></td>
                            </tr>
                            <tr>
                                <th className="pe-3">{__('Storage')}</th>
                                <td>{bill.Time === null ? '...' : (<FormatCurrency>{bill.Time}</FormatCurrency>)}</td>
                            </tr>
                            <tr>
                                <th className="pe-3">{__('Handover')}</th>
                                <td><FormatCurrency>{bill.Handover}</FormatCurrency></td>
                            </tr>
                            <tr>
                                <th className="pe-3">{__('Grand Total')}</th>
                                <td className="text-primary"><b><FormatCurrency>{bill.Total}</FormatCurrency></b></td>
                            </tr>
                        </tbody>
                    </table>
                ) : null}
                {props.onDismiss ? (
                    <div className="btns">
                        <MDBBtn type="button" onClick={props.onDismiss}>{__('Close')}</MDBBtn>
                    </div>
                ) : null}
            </div>
            {props.onDismiss ? (<div className="dismiss" onClick={props.onDismiss} />) : null}
        </>
    );
}
