import React from "react";
import { Columns } from "../../component/Edit";
import useLocale from "../../util/i18n";
import { useCrumbs } from "../../component/breadcrumbs";
import Config from "./Config";
import { Helmet } from "react-helmet-async";

export default function ConfigPrice() {
    let [__] = useLocale();
    let [_, setCrumbs] = useCrumbs();
    let columns = React.useMemo<Columns>(() => ({
        [__('Pricing')]: [
            {
                name: 'price/algorithm',
                label: __('Algorithm'),
                type: 'select',
                required: true,
                options: [
                    { value: 'round', label: __('Round') },
                    { value: 'floor', label: __('Floor') },
                    { value: 'ceil', label: __('Ceil') },
                ]
            },
            {
                name: 'price/price',
                label: __('Unit Price'),
                type: 'price',
                required: true
            },
            {
                name: 'price/seconds',
                label: __('Billing Cycle') + '(' + __('in Seconds') + ')',
                type: 'number',
                required: true
            },
            {
                name: 'price/handover',
                label: __('Handover(Per Car)'),
                type: 'price',
                required: true
            },
            {
                name: 'price/freight',
                label: __('Default Freight'),
                type: 'price',
                required: true
            }
        ]
    }), [__]);
    React.useEffect(() => {
        setCrumbs(['Pricing Config']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Pricing Config')}</title>
            </Helmet>
            <Config columns={columns} />
        </>
    );
}