import React from "react";
import { useSearchParams } from "react-router-dom";
import Grid, { Columns } from "../../component/Grid";
import useLocale from "../../util/i18n";
import { useCrumbs } from "../../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import { getSubscriber } from "../../api/email";

export default function Subscriber() {
    let [query, setQuery] = useSearchParams();
    let [__, lang] = useLocale();
    let columns = React.useMemo<Columns>(() => ({
        email: {
            label: 'Email',
            format: value => (<a href={'mailto:' + value} target="_blank" rel="noreferrer,nofollow,noopener">{value}</a>)
        },
        customer_id: {
            label: __('Customer'),
            className: 'w-1 text-center text-nowrap'
        },
        created_at: {
            label: __('Subscribed at'),
            className: 'w-1 text-center text-nowrap',
            format: value => Intl.DateTimeFormat(lang).format(value)
        },
        status: {
            label: __('Status'),
            className: 'w-1 text-center text-nowrap',
            format: value => __(value === true || value.toString() === '1' ? 'Available' : 'Unsubscribed')
        }
    }), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        return getSubscriber(params);
    });
    let [_, setCrumbs] = useCrumbs();
    React.useEffect(() => {
        setCrumbs(['Subscriber']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Subscriber')}</title>
            </Helmet>
            <Grid columns={columns} query={query} setQuery={setQuery} doLoad={doLoad.current} />
        </>
    );
}
