import React from "react";
import InputBox from "./input-box";
import { CurrencyContext } from "../util/currency";

interface PriceBoxProps {
    name: string;
    label?: string;
    required?: boolean;
    comment?: string;
    value?: string;
    readOnly?: boolean;
    disabled?: boolean;
    max?: string | number;
    min?: string | number;
    step?: string | number;
    isFixed?: string | number | boolean;
    fixed?: string;
    onChange?: (value: string) => void;
    onChangeFixed?: (value: string) => void;
}

export default function PriceBox(props: PriceBoxProps) {
    let [value, setValue] = React.useState<string>('');
    let [fixed, setFixed] = React.useState('1');
    let [currency] = React.useContext(CurrencyContext);
    const onChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => (props.onChange || setValue)(e.target.value), [props.onChange, setValue]);
    const onChangeFixed = React.useCallback((fixed: string, e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        e.preventDefault();
        (props.onChangeFixed || setFixed)(fixed);
    }, [props.onChangeFixed, setFixed]);
    React.useEffect(() => {
        setValue(typeof props.value === 'undefined' || props.value === null ? '' : parseFloat((props.value || '0').toString()).toString());
    }, [props.value]);
    React.useEffect(() => {
        setFixed(props.isFixed === true || props.isFixed?.toString() === '1' ? '1' : '0');
    }, [props.isFixed]);
    return (
        <InputBox label={props.label} required={props.required} comment={props.comment}>
            <>
                <span className="input-group-text">{currency}</span>
                {props.fixed && fixed === '1' ? (
                    <span className="input-group-text" style={{ cursor: 'pointer' }} onClick={onChangeFixed.bind(null, '0')}>
                        <input type="hidden" name={props.fixed} value="1" />
                        <span className="fa fa-plus-minus" />
                    </span>
                ) : null}
                <input
                    name={props.name} className="form-control active" type="number"
                    value={value} onChange={onChange}
                    max={props.max} min={props.min} step={props.step}
                    required={props.required} readOnly={props.readOnly} disabled={props.disabled}
                />
                {props.fixed && fixed !== '1' ? (
                    <span className="input-group-text" style={{ cursor: 'pointer' }} onClick={onChangeFixed.bind(null, '1')}>
                        <input type="hidden" name={props.fixed} value="0" />
                        <span className="fa fa-percent" />
                    </span>
                ) : null}
            </>
        </InputBox>
    );
}