import React from 'react';
import './base.scss';
import { LocaleProvider } from './util/i18n';
import { Route, Routes } from 'react-router-dom';
import Header from './component/header';
import { HelmetProvider } from 'react-helmet-async';
import { PayloadProvider, usePayload } from './util/token';
import Dashboard from './page/Dashboard';
import Nav from './component/nav';
import Login from './page/Login';
import Toast from './component/toast';
import { CrumbsProvider } from './component/breadcrumbs';
import { CurrencyProvider } from './util/currency';
import ConfigGeneral from './page/config/General';
import Subscriber from './page/email/Subscriber';
import EmailTmplGrid from './page/email/template/Grid';
import EmailTmplEdit from './page/email/template/Edit';
import EmailQueue from './page/email/Queue';
import ConfigEmail from './page/config/Email';
import WarehouseGrid from './page/warehouse/Grid';
import WarehouseEdit from './page/warehouse/Edit';
import ConfigPrice from './page/config/Price';
import MaintenanceGrid from './page/maintenance/Grid';
import InventoryGrid from './page/inventory/Grid';
import InventoryEdit from './page/inventory/Edit';
import UserEdit from './page/user/Edit';
import UserGrid from './page/user/Grid';
import Me from './page/user/Me';

export default function App() {
    React.useEffect(() => {
        if ('ontouchend' in window) {
            document.documentElement.classList.add('touchevents');
        } else {
            document.documentElement.classList.add('notouchevents');
        }
    }, []);
    return (
        <Providers>
            <>
                <Content />
                <Toast />
            </>
        </Providers>
    );
}

function Providers(props: { children: JSX.Element }) {
    return (
        <LocaleProvider init={typeof localStorage !== 'undefined' ? localStorage.getItem('lang') || undefined : undefined}>
            <HelmetProvider>
                <CrumbsProvider>
                    <PayloadProvider>
                        <CurrencyProvider>
                            {props.children}
                        </CurrencyProvider>
                    </PayloadProvider>
                </CrumbsProvider>
            </HelmetProvider>
        </LocaleProvider>
    );
}

function Content() {
    let [payload] = usePayload();
    return payload?.admin ? (
        <>
            <Header />
            <Nav />
            <main className="main-content">
                <Routes>
                    <Route index path="/" element={<Dashboard />} />
                    <Route path="/config/general" element={<ConfigGeneral />} />
                    <Route path="/config/email" element={<ConfigEmail />} />
                    <Route path="/config/price" element={<ConfigPrice />} />
                    <Route path="/email/template" element={<EmailTmplGrid />} />
                    <Route path="/email/template/edit" element={<EmailTmplEdit />} />
                    <Route path="/email/queue" element={<EmailQueue />} />
                    <Route path="/email/subscriber" element={<Subscriber />} />
                    <Route path="/warehouse" element={<WarehouseGrid />} />
                    <Route path="/warehouse/edit" element={<WarehouseEdit />} />
                    <Route path="/maintenance" element={<MaintenanceGrid />} />
                    <Route path="/inventory" element={<InventoryGrid />} />
                    <Route path="/inventory/edit" element={<InventoryEdit />} />
                    <Route path="/user" element={<UserGrid />} />
                    <Route path="/user/edit" element={<UserEdit />} />
                    <Route path="/user/me" element={<Me />} />
                </Routes>
            </main>
        </>
    ) : (
        <Login />
    );
}
