import React from "react";
import { MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBTable, MDBTableBody } from "mdb-react-ui-kit";
import { getMaintenance, InventoryMaintenanceData, saveInventoryMaintenance } from "../api/warehouse";
import Select, { OptionType } from "./select";
import useLocale from "../util/i18n";
import FormatCurrency from "../util/currency";

export default function Maintenance(props: { inventoryId: number, warehouseId: number, dismiss: () => void }) {
    let [data, setData] = React.useState<Array<InventoryMaintenanceData>>();
    let [options, setOptions] = React.useState<Array<OptionType>>([]);
    let [__] = useLocale();
    let open = React.useMemo(() => Boolean(props.inventoryId), [props.inventoryId]);
    let StatusOptions = React.useMemo(() => [
        { value: '0', label: __('Processing') },
        { value: '1', label: __('Complete') }
    ], [__]);
    let reload = React.useCallback(() => {
        if (props.inventoryId) {
            getMaintenance({ filter: { inventory_id: props.inventoryId } }).then(response =>
                setData(typeof response !== 'string' ? response.data : []),
                () => setData([]));
        } else {
            setData(undefined);
        }
    }, [props.inventoryId]);
    let debounce = React.useRef(false);
    let doComplete = React.useCallback((maintenance_id: number, i: number, e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (debounce.current) {
            return;
        }
        debounce.current = true;
        saveInventoryMaintenance({ maintenance_id, inventory_id: props.inventoryId, status: 1, finished_at: 1 }).then(() => {
            let result = [...(data || [])];
            result[i].process = 1;
            setData(result);
            debounce.current = false;
        });
    }, [setData, data, props.inventoryId]);
    let onSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        let params = new FormData(e.target as HTMLFormElement);
        if (params.get('status')?.toString() == '1') {
            params.set('finished_at', '1');
        }
        let mid = params.get('maintenance_id')!.toString();
        for (let i of (data || [])) {
            if (i.id.toString() === mid) {
                if (window.confirm(__('The maintenance exists. Would you like to superpose?'))) {
                    params.set('qty', (parseFloat(i.qty.toString()) + parseFloat(params.get('qty') as string)).toString());
                    break;
                } else {
                    return;
                }
            }
        }
        debounce.current = true;
        saveInventoryMaintenance(params).then(() => {
            setData(undefined);
            reload();
            debounce.current = false;
        });
    }, [props.inventoryId, data, setData, __, reload]);
    React.useEffect(reload, [reload]);
    const cached = React.useRef<{ [id: number]: Array<OptionType> }>({});
    React.useEffect(() => {
        if (props.warehouseId && !cached.current[props.warehouseId]) {
            getMaintenance({ filter: { warehouse_id: props.warehouseId }, limit: 1000 }).then(response => {
                if (typeof response !== 'string') {
                    let options: Array<OptionType> = [];
                    for (let i of response.data) {
                        options.push({ value: i.id.toString(), label: __(i.name) });
                    }
                    setOptions(options);
                    cached.current[props.warehouseId] = options;
                }
            });
        }
    }, [props.warehouseId, __]);
    return (
        <MDBModal open={open} onClose={props.dismiss} tabIndex='-1'>
            <MDBModalDialog centered>
                <MDBModalContent>
                    <MDBModalBody>
                        <form onSubmit={onSubmit} className="d-flex flex-column gap-2">
                            <input type="hidden" name="inventory_id" value={props.inventoryId} />
                            <Select name="maintenance_id" label={__('Maintenance')} options={options} />
                            <MDBInput name="qty" label={__('Quantity')} defaultValue={1} min="0.00000001" step="0.00000001" />
                            <Select name="status" label={__('Status')} options={StatusOptions} />
                            <MDBBtn type="submit">{__('Save')}</MDBBtn>
                        </form>
                        <MDBTable hover borderless className="mt-3 mb-0">
                            <colgroup>
                                <col />
                                <col width="1" />
                            </colgroup>
                            <MDBTableBody>
                                {typeof data === 'undefined' ? (
                                    <tr><td colSpan={2}><span className="fa fa-spin fa-spinner" /></td></tr>
                                ) : data.map((item, i) => (
                                    <tr key={item.id}>
                                        <th>{__(item.name)}</th>
                                        <td className="text-end">
                                            {item.process ? (
                                                <FormatCurrency>{item.price * item.qty}</FormatCurrency>
                                            ) : (
                                                <MDBBtn type="button" onClick={doComplete.bind(null, item.id, i)}>{__('Complete')}</MDBBtn>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                            <tfoot>
                                <tr>
                                    <td colSpan={2} className="p-0">
                                        <MDBBtn type="button" onClick={props.dismiss} block color="light">{__('Close')}</MDBBtn>
                                    </td>
                                </tr>
                            </tfoot>
                        </MDBTable>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
}
