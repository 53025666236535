import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Grid, { Actions, Columns } from "../../component/Grid";
import useLocale from "../../util/i18n";
import { useCrumbs } from "../../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import { getWarehouse, WarehouseData } from "../../api/warehouse";

export default function WarehouseGrid() {
    let [query, setQuery] = useSearchParams();
    let [__] = useLocale();
    let navigate = useNavigate();
    let columns = React.useMemo<Columns>(() => ({
        id: {
            label: 'ID',
            className: 'w-1 text-center text-nowrap'
        },
        title: {
            label: __('Title'),
            format: value => __(value)
        },
        phone: {
            label: __('Phone'),
            className: 'w-1 text-center text-nowrap'
        },
        address: {
            label: __('Address')
        },
        status: {
            label: __('Status'),
            className: 'w-1 text-center text-nowrap',
            type: 'select',
            options: [
                { value: '1', label: __('Enabled') }, { value: '0', label: __('Disabled') }
            ],
            format: (value: number) => value ? __('Enabled') : __('Disabled')
        }
    }), [__]);
    let actions = React.useMemo<Actions>(() => ([
        {
            label: __('Edit'),
            onClick: (item: WarehouseData) => navigate('/warehouse/edit?id=' + item.id)
        },
        {
            label: __('Inventory'),
            onClick: (item: WarehouseData) => navigate('/inventory?filter%5Bwarehouse_id%5D=' + item.id)
        }
    ]), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        params.user_id = 1;
        return getWarehouse(params);
    });
    let [_, setCrumbs] = useCrumbs();
    React.useEffect(() => {
        setCrumbs(['Warehouse Management']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Warehouse Management')}</title>
            </Helmet>
            <Grid columns={columns} actions={actions} query={query} setQuery={setQuery} doLoad={doLoad.current} />
        </>
    );
}
