import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Grid, { Actions, Columns } from "../../../component/Grid";
import useLocale from "../../../util/i18n";
import { TemplateData, deleteTemplate, getTemplate, sendEmail } from "../../../api/email";
import { useCrumbs } from "../../../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import Toast from "../../../component/toast";
import { MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter } from "mdb-react-ui-kit";
import Select from "../../../component/select";

export default function EmailTmplGrid() {
    let [query, setQuery] = useSearchParams();
    let [__] = useLocale();
    let navigate = useNavigate();
    let [send, setSend] = React.useState('');
    let columns = React.useMemo<Columns>(() => ({
        code: {
            label: __('Code'),
            sortable: 'code',
            className: 'w-1 text-nowrap',
            filterable: 'code'
        },
        subject: {
            label: __('Subject')
        },
        language: {
            label: __('Language'),
            className: 'w-1 text-nowrap',
            format: value => value || __('All')
        },
        status: {
            label: __('Status'),
            className: 'w-1 text-center text-nowrap',
            filterable: 'status',
            type: 'select',
            options: [
                { value: '1', label: __('Enabled') }, { value: '0', label: __('Disabled') }
            ],
            format: (value: number) => value ? __('Enabled') : __('Disabled')
        }
    }), [__]);
    let actions = React.useMemo<Actions>(() => ([
        {
            label: __('Edit'),
            condition: (item: Record<string, any>) => item.package_id && item.package_id.toString() !== '0',
            onClick: (item: TemplateData) => navigate('/email/template/edit?id=' + item.id)
        },
        {
            label: __('Delete'),
            condition: (item: Record<string, any>) => item.package_id && item.package_id.toString() !== '0',
            onClick: (item: TemplateData) => {
                if (window.confirm(__('Are you sure to delete this?'))) {
                    deleteTemplate(item.id).then(response => Toast.show(response));
                }
            }
        },
        {
            label: __('Send'),
            onClick: (item: TemplateData) => {
                setSend(item.code);
            }
        }
    ]), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        return getTemplate(params);
    });
    let [_, setCrumbs] = useCrumbs();
    React.useEffect(() => {
        setCrumbs(['Email Template']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Email Template')}</title>
            </Helmet>
            <Grid columns={columns} newUrl="/email/template/edit" actions={actions} query={query} setQuery={setQuery} doLoad={doLoad.current} />
            <ModalSend code={send} onHide={setSend.bind(null, '')} />
        </>
    );
}

function ModalSend(props: { code: string, onHide?: () => void }) {
    let [status, setStatus] = React.useState(false);
    let [rcpt, setRcpt] = React.useState('');
    let [custom, setCustom] = React.useState('');
    let [__] = useLocale();
    React.useEffect(() => {
        if (!status && props.onHide) {
            props.onHide();
        }
    }, [status, props.onHide]);
    React.useEffect(() => {
        if (props.code) {
            setRcpt('');
            setStatus(true);
        }
    }, [props.code]);
    const onSubmit = React.useRef((e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setStatus(false);
        let data = new FormData(e.target as HTMLFormElement);
        if (data.get('scheduled_at')) {
            data.set('scheduled_at', Math.round((new Date(data.get('scheduled_at') as string)).getTime() / 1000).toString());
        }
        sendEmail(data).then(response => {
            Toast.show(response);
        });
    });
    return (
        <MDBModal open={status} setOpen={setStatus}>
            <MDBModalDialog centered>
                <MDBModalContent tag="form" onSubmit={onSubmit}>
                    <MDBModalBody>
                        <input type="hidden" name="code" value={props.code} />
                        <Select options={[{ value: 's', label: __('Subscribers') }]} onChange={setCustom} wrapperClass="mb-3" label={__('Recipient')}>
                            <option value="">{__('Custom')}</option>
                        </Select>
                        <MDBInput
                            hidden={custom === 's'} className="active"
                            label={__('Custom Recipient')} name="rcpt" value={rcpt}
                            onChange={e => setRcpt(e.target.value)}
                        />
                        <MDBInput type="datetime-local" name="scheduled_at" label={__('Scheduled at')} className="mt-3" />
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn type="button" color="light" onClick={setStatus.bind(null, false)}>{__('Cancel')}</MDBBtn>
                        <MDBBtn type="submit">{__('Send')}</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
}
