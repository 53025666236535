import React from "react";

type Urls = Array<string | { src: string, type: number,  }>;

export interface LightBoxRef {
    show: (urls: Urls, index: number) => void;
    hide: () => void;
}

export default React.forwardRef<LightBoxRef, {}>(function LightBox(_, ref) {
    let [urls, setUrls] = React.useState<Urls>([]);
    let [index, setIndex] = React.useState(0);
    let [status, setStatus] = React.useState(false);
    let handler = React.useMemo<LightBoxRef>(() => ({
        show: (urls, index = 0) => {
            setUrls(urls);
            setIndex(index);
            setStatus(true);
        },
        hide: () => setStatus(false)
    }), [status]);
    React.useImperativeHandle(ref, () => handler, [handler]);
    return status ? (
        <div className="lightbox">
            <div className="mask" onClick={handler.hide}></div>
            {urls.length > 1 ? (
                <ul className="list">
                    {urls.map((url, i) => (
                        <li key={'l-' + i} className={i === index ? 'active' : ''} onClick={setIndex.bind(null, i)}>
                            <img src={typeof url === 'string' ? url : url.src} alt="" width="50" height="50" />
                        </li>
                    ))}
                </ul>
            ) : null}
            {typeof urls[index] === 'string' || urls[index].type === 0 ? (
                <img src={typeof urls[index] === 'string' ? urls[index] : urls[index].src} alt="" className="current" />
            ) : (
                <video src={typeof urls[index] === 'string' ? urls[index] : urls[index].src} controls={true} autoPlay={true} className="current" />
            )}
        </div>
    ) : null;
});
