import React from "react";
import { Helmet } from "react-helmet-async";
import Grid, { Columns } from "../../component/Grid";
import { useCrumbs } from "../../component/breadcrumbs";
import { changePassword, deleteUser, getUser, UserData } from "../../api/admin";
import { useNavigate, useSearchParams } from "react-router-dom";
import useLocale from "../../util/i18n";
import Toast from "../../component/toast";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog } from "mdb-react-ui-kit";

export default function UserGrid() {
    let [query, setQuery] = useSearchParams();
    let [password, setPassword] = React.useState('');
    let [__] = useLocale();
    let navigate = useNavigate();
    let columns = React.useMemo<Columns>(() => ({
        id: {
            label: 'ID'
        },
        username: {
            label: __('Username')
        },
        email: {
            label: __('Email Address'),
            format: (value: string) => (<a href={'mailto:' + value} target="_blank" rel="noreferrer">{value}</a>)
        },
        status: {
            label: __('Status'),
            className: 'w-1 text-center text-nowrap',
            filterable: 'status',
            type: 'select',
            options: [
                { value: '1', label: __('Enabled') }, { value: '0', label: __('Enabled') }
            ],
            format: (value: number) => value > 0 ? __('Enabled') : __('Enabled')
        }
    }), [__]);
    let actions = React.useMemo(() => ([
        {
            label: __('Edit'),
            onClick: (item: UserData) => navigate('/user/edit?id=' + item.id)
        },
        {
            label: __('Reset Password'),
            onClick: (item: UserData) => {
                changePassword({ user_id: item.id, password: '1' }).then(response => {
                    typeof response !== 'string' && setPassword(response.password)
                });
            }
        },
        {
            label: __('Delete'),
            onClick: (item: UserData) => {
                if (window.confirm(__('Are you sure to delete this?'))) {
                    deleteUser({ id: item.id }).then(response => Toast.show(response));
                }
            }
        }
    ]), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        return getUser(params);
    });
    let [_, setCrumbs] = useCrumbs();
    React.useEffect(() => {
        setCrumbs(['User Management']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('User Management')}</title>
            </Helmet>
            <Grid newUrl="/user/edit" columns={columns} actions={actions} query={query} setQuery={setQuery} doLoad={doLoad.current} />
            <MDBModal open={Boolean(password)} onClose={setPassword.bind(null, '')}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalBody className="d-flex flex-column align-items-center">
                            <h4>{__('The new password is')}</h4>
                            <p>{password}</p>
                            <MDBBtn type="button" color="secondary" onClick={setPassword.bind(null, '')}>{__('Close')}</MDBBtn>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}
