import React from 'react';
import { MDBBtn, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from 'mdb-react-ui-kit';
import { usePayload } from '../util/token';
import Breadcrumbs from './breadcrumbs';
import useLocale from '../util/i18n';
import { Link, useLocation } from 'react-router-dom';

export default function Header() {
    let [_1, _2, logout] = usePayload();
    let [__, _, setLang] = useLocale();
    let location = useLocation();
    let [newUrl, setNewUrl] = React.useState<string>();
    React.useEffect(() => {
        window.setTimeout(() => {
            let grid = document.querySelector('[data-new-url]') as HTMLDivElement | null;
            if (grid) {
                setNewUrl(grid.dataset.newUrl);
            } else {
                setNewUrl(undefined);
            }
        }, 600);
    }, [location]);
    const toggleNav = React.useRef(() => {
        let l = document.querySelector('.nav-container')?.classList;
        if (l?.contains('active')) {
            l?.remove('active');
        } else {
            l?.add('active');
        }
    });
    const onNew = React.useRef((e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        document.dispatchEvent(new Event('new'));
    });
    return (
        <header className="header">
            <div className="tools d-block d-md-none">
                <MDBBtn onClick={toggleNav.current} color="link" title={__('Menu')}>
                    <span className="fa fa-fw fa-bars" />
                </MDBBtn>
            </div>
            <div className="d-flex flex-row justify-content-center justify-content-md-start">
                <img src="https://sydneycitymgservice.oss-ap-southeast-2.aliyuncs.com/logo.svg" className="logo d-none d-md-block" alt="Logo" />
                <Breadcrumbs />
            </div>
            <div className="tools">
                {newUrl ? (
                    (<MDBBtn onClick={onNew.current} color="link" title={__('Create New Data')}>
                        <span className="fa fa-fw fa-square-plus" aria-hidden="true" />
                        <span className="sr-only">{__('Create New Data')}</span>
                    </MDBBtn>)
                ) : null}
                <MDBDropdown>
                    <MDBDropdownToggle color="link">
                        <span className="fa fa-fw fa-language" aria-hidden="true" />
                        <span className="sr-only">{__('Language')}</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem link onClick={setLang.bind(null, 'en')}>English</MDBDropdownItem>
                        <MDBDropdownItem link onClick={setLang.bind(null, 'zh-CN')}>简体中文</MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>
                <MDBBtn onClick={logout} color="link" title={__('Logout')}>
                    <span className="fa fa-fw fa-right-from-bracket" aria-hidden="true" />
                    <span className="sr-only">{__('Logout')}</span>
                </MDBBtn>
            </div>
        </header>
    );
}
