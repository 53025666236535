import React, { ChangeEvent } from "react";
import { useCrumbs } from "../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import useLocale, { LocaleString } from "../util/i18n";
import { BillData, bindInventory, deliverInventory, exportInventory, getInventory, getInventoryBill, getMaintenance, getWarehouse, InventoryData, MaintenanceData, ModifiedData, PricingData, reviewInventory, saveInventory, saveInventoryMaintenance, WarehouseData } from "../api/warehouse";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBInput, MDBListGroup, MDBListGroupItem, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import { useRbac } from "../util/token";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import Toast from "../component/toast";
import Loading, { LoadingRef } from "../component/loading";
import Bill from "../component/bill";
import { defaultListResponse } from "../api/types";

const VersionContext = React.createContext<[number, () => void]>([0, () => { }]);
const SearchContext = React.createContext<[Record<number, string>, React.Dispatch<React.SetStateAction<Record<number, string>>>]>([{}, () => { }]);
const Colors = ['Black', 'White', 'Gray', 'Red', 'Blue', 'Golden', 'Others'];
const Models = ['MG3', 'MG ZS', 'MG ZST', 'MG ZSEV', 'MG HS', 'MG HSEV', 'MG4', 'MG5', 'Others'];

function format(time: number | Date | null | undefined, lang = 'en', standard = '', withTime = true) {
    if (!time) {
        return '';
    }
    let date = typeof time === 'number' ? new Date(time) : time;
    let parts = [date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes()];
    let result = withTime ? (parts[2] < 10 ? '0' + parts[2] : parts[2]) + ':' + (parts[3] < 10 ? '0' + parts[3] : parts[3]) : '';
    if (lang.startsWith('zh') || standard) {
        return date.getFullYear() + '-' + (parts[0] < 10 ? '0' + parts[0] : parts[0]) + '-' + (parts[1] < 10 ? '0' + parts[1] : parts[1]) + (standard || ' ') + result;
    } else {
        return (parts[1] < 10 ? '0' + parts[1] : parts[1]) + '/' + (parts[0] < 10 ? '0' + parts[0] : parts[0]) + '/' + date.getFullYear() + ' ' + result;
    }
};

export default function Dashboard() {
    let [version, setVersion] = React.useState(0);
    let [search, setSearch] = React.useState<Record<number, string>>({});
    let [type, setType] = React.useState(0);
    let [warehouses, setWarehouses] = React.useState<Array<WarehouseData>>();
    let [maintenances, setMaintenances] = React.useState<Array<MaintenanceData>>();
    let [isGranted] = useRbac();
    let [_, setCrumbs] = useCrumbs();
    let [__] = useLocale();
    let doChangeVersion = React.useCallback(() => {
        version && sessionStorage.clear();
        setVersion(v => v + 1);
    }, [version, setVersion]);
    let refresh = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        doChangeVersion();
    }, [doChangeVersion]);
    const loading = React.useRef<LoadingRef>(null);
    let doExport = React.useCallback((e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let checked = document.querySelectorAll<HTMLElement>('.inventory.checked');
        if ((checked?.length || 0) > 0) {
            loading.current?.show(true);
            let id: Array<number> = [];
            checked?.forEach(e => e.dataset.id && id.push(parseInt(e.dataset.id)));
            exportInventory({ id }).then(blob => {
                loading.current?.hide();
                const a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = 'export.xls';
                a.click();
            }, loading.current?.hide);
        }
    }, []);
    React.useEffect(() => {
        if (!isGranted('warehouseEntry')) {
            getWarehouse({ limit: 100 }).then(response => {
                if (typeof response !== 'string') {
                    setWarehouses(response.data);
                }
            });
        }
    }, [version, isGranted]);
    React.useEffect(() => {
        setCrumbs(['Dashboard']);
        getWarehouse({ limit: 100 }).then(response => {
            if (typeof response !== 'string') {
                setWarehouses(response.data);
            }
        });
        getMaintenance({ limit: 100 }).then(response => typeof response !== 'string' && setMaintenances(response.data));
    }, []);
    return (
        <VersionContext.Provider value={[version, doChangeVersion]}>
            <SearchContext.Provider value={[search, setSearch]}>
                <Helmet prioritizeSeoTags>
                    <title>{__('Dashboard')}</title>
                </Helmet>
                <div className="dashboard">
                    {warehouses?.length ? (
                        warehouses?.map(warehouse => (
                            <MDBCard key={'warehouse-' + warehouse.id}>
                                <MDBCardHeader>
                                    <h4>{warehouse.title ? __(warehouse.title) : warehouse.address}</h4>
                                    <div className="btns">
                                        <Search warehouseId={warehouse.id} />
                                        <a href="#" onClick={refresh} title={__('Reload')}>
                                            <span className="fa fa-fw fa-refresh" />
                                        </a>
                                        {isGranted('inventoryExport') && type === 2 ? (
                                            <a href="#" onClick={doExport} title={__('Export')}>
                                                <span className="fa fa-fw fa-file-arrow-down" />
                                            </a>
                                        ) : null}
                                        {isGranted('saveWarehouse') ? (
                                            <Link to={'/warehouse/edit?id=' + warehouse.id} title={__('Edit')}>
                                                <span className="fa fa-fw fa-pen-to-square" />
                                            </Link>
                                        ) : null}
                                        {warehouse.phone ? (
                                            <a href={'tel:' + warehouse.phone} target="_blank" rel="noreferrer" className="call">
                                                <span className="fa fa-fw fa-phone" />
                                            </a>
                                        ) : null}
                                        {warehouse.lat && warehouse.lng ? (
                                            <a href={'https://www.google.com/maps/@' + warehouse.lat + ',' + warehouse.lng + ',17z'} target="_blank" rel="noreferrer">
                                                <span className="fa fa-fw fa-location-dot" />
                                            </a>
                                        ) : null}
                                    </div>
                                </MDBCardHeader>
                                <MDBCardBody className="p-0 p-lg-3">
                                    {type === 2 || !isGranted('warehouseEntry') ? (
                                        <Charge
                                            warehouseId={warehouse.id}
                                            maintenances={maintenances}
                                        />
                                    ) : (type === 1 ? (
                                        <StockIn warehouseId={warehouse.id} />
                                    ) : (
                                        <Stock warehouseId={warehouse.id} />
                                    ))}
                                </MDBCardBody>
                            </MDBCard>
                        ))) : (
                        <div className="empty h-100 d-flex flex-column align-items-center justify-content-center">
                            <span className="fa fa-table-cells-large fa-4x mb-3" />
                            <span>{__('No Data to Display')}</span>
                        </div>
                    )}
                </div>
                {isGranted('warehouseEntry') ? (
                    <MDBTabs pills>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={setType.bind(null, 0)} active={type === 0}>
                                {__('Stock')}
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={setType.bind(null, 1)} active={type === 1}>
                                {__('Stock in')}
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={setType.bind(null, 2)} active={type === 2}>
                                {__('Service Charge')}
                            </MDBTabsLink>
                        </MDBTabsItem>
                    </MDBTabs>
                ) : (
                    <BindCar />
                )}
                <Loading ref={loading} />
            </SearchContext.Provider>
        </VersionContext.Provider>
    );
};

function Search(props: { warehouseId: number }) {
    let [search, setSearch] = React.useContext(SearchContext);
    let [value, setValue] = React.useState(search[props.warehouseId]);
    let onChangeSearch = React.useCallback((e: React.MouseEvent | React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setSearch({ ...search, [props.warehouseId]: value });
    }, [props.warehouseId, search, setSearch, value]);
    return (
        <form onSubmit={onChangeSearch}>
            <button type="submit">
                <span className="fa fa-fw fa-magnifying-glass" />
            </button>
            <input type="search" value={value} onChange={e => setValue(e.target.value)} />
        </form>
    );
}

function StockIn(props: { warehouseId: number }) {
    let [data, setData] = React.useState<Array<Partial<InventoryData>>>([{}]);
    let [sort, setSort] = React.useState('created_at DESC');
    let [filter, setFilter] = React.useState(604800);
    let [__] = useLocale();
    let [version, setVersion] = React.useContext(VersionContext);
    let [search] = React.useContext(SearchContext);
    const table = React.useRef<HTMLDivElement>(null);
    const exists = React.useRef<{ vin: Array<string>, rego: Array<string> }>({ vin: [], rego: [] });
    let onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let p = e.target.name.lastIndexOf('[');
        let i = parseInt(e.target.name.substring(p + 1, e.target.name.length - 1));
        let result = structuredClone(data);
        if (e.target.name.startsWith('info[')) {
            let key = e.target.name.substring(5, p - 1);
            result[i].info ??= {};
            result[i].info[key] = e.target.value;
            if (key === 'rego') {
                let flag = exists.current.rego.includes(e.target.value);
                if (!flag) {
                    for (let i of data) {
                        if (i.info?.rego === e.target.value) {
                            flag = true;
                            break;
                        }
                    }
                }
                if (flag) {
                    e.target.setCustomValidity(__('The vehicle with same %s already exists.').replace('%s', __('Rego')));
                } else {
                    e.target.setCustomValidity('');
                }
            }
        } else {
            let key = e.target.name.substring(0, p) as keyof InventoryData;
            result[i][key] = e.target.value;
            if (key === 'sku') {
                let flag = exists.current.vin.includes(e.target.value);
                if (!flag) {
                    for (let i of data) {
                        if (i.info?.rego === e.target.value) {
                            flag = true;
                            break;
                        }
                    }
                }
                if (flag) {
                    e.target.setCustomValidity(__('The vehicle with same %s already exists.').replace('%s', __('VIN')));
                } else {
                    e.target.setCustomValidity('');
                }
            }
        }
        setData(result);
    }, [data, __]);
    let clear = React.useCallback((i: number, e: React.MouseEvent) => {
        e.preventDefault();
        let result = [...data];
        result[i] = {};
        setData(result);
    }, [data]);
    let more = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        setData([{}, {}, {}, ...data])
    }, [data]);
    let save = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        if (table.current?.querySelector('.btn.changed')) {
            Toast.show(__('There has been changed data to confirm. Please save again after confirmation.'), 'danger');
            return;
        }
        let invalid = table.current?.querySelector<HTMLInputElement>(':invalid');
        if (invalid?.validationMessage) {
            Toast.show(invalid.validationMessage, 'danger');
            return;
        }
        let ps = [];
        for (let item of data) {
            if (item.sku) {
                item.warehouse_id = props.warehouseId;
                item.qty = 1;
                if (item.created_at) {
                    if (/[^\d\.\-]/.test(item.created_at.toString())) {
                        item.created_at = (new Date(item.created_at)).getTime() / 1000;
                    }
                } else {
                    delete item.created_at;
                }
                ps.push(saveInventory(item));
            }
        }
        Promise.all(ps).then(() => {
            setData([{}]);
            setVersion();
            Toast.show(__('Data has been saved successfully.'), 'success')
        });
    }, [data, props.warehouseId, __, setVersion]);
    let onSort = React.useCallback((key: string, e: React.MouseEvent | React.ChangeEvent) => {
        if (!key) {
            return;
        }
        if (key.includes(' ')) {
            setSort(key);
        } else if (sort.startsWith(key)) {
            setSort(key + ' ' + (sort.endsWith('ASC') ? 'DESC' : 'ASC'));
        } else {
            setSort(key + ' DESC');
        }
    }, [sort]);
    const doLoad = React.useRef((filter: any, cacheKey: string) => new Promise<string | defaultListResponse<InventoryData> & { config: PricingData }>(resolve => {
        let cache = sessionStorage.getItem(cacheKey);
        if (cache) {
            resolve(JSON.parse(cache));
        } else {
            getInventory({
                filter, sort: 'main.status DESC, main.' + sort, limit: 100
            }).then(resolve);
        }
    }));
    React.useEffect(() => {
        let key = props.warehouseId + sort + filter + 'q:' + (search[props.warehouseId] || '');
        doLoad.current({ warehouse_id: props.warehouseId, q: search[props.warehouseId] || '', ...(filter > 0 ? { created_at: { gte: (new Date).getTime() / 1000 - filter } } : {}) }, key).then(response => {
            if (typeof response !== 'string') {
                setData([{}, ...response.data]);
                sessionStorage.setItem(key, JSON.stringify(response));
                if (response.data.length === 0 && search[props.warehouseId]) {
                    Toast.show(__('No Data Matching %s').replace('%s', search[props.warehouseId]));
                }
            }
        });
        let existsKey = props.warehouseId + 'created_at DESCstatus=1q:';
        doLoad.current({ warehouse_id: props.warehouseId, status: 1 }, existsKey).then(response => {
            if (typeof response !== 'string') {
                sessionStorage.setItem(key, JSON.stringify(response));
                for (let i of response.data) {
                    i.sku && exists.current.vin.push(i.sku);
                    i.info?.rego && exists.current.rego.push(i.info.rego);
                }
            }
        });
    }, [props.warehouseId, sort, version, filter, search]);
    return (
        <>
            <div className="d-flex flex-wrap gap-3 d-lg-table" ref={table}>
                <div className="inventory head">
                    <h6 className={sort === 'info[model] ASC' ? 'asc' : (sort === 'info[model] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[model]')}>{__('Model')}</h6>
                    <h6 className={sort === 'sku ASC' ? 'asc' : (sort === 'sku DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'sku')} style={{ minWidth: '17ch' }}>{__('VIN')}</h6>
                    <h6 className={sort === 'created_at ASC' ? 'asc' : (sort === 'created_at DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'created_at')}>{__('Stock in')}</h6>
                    <h6 className={sort === 'info[rego] ASC' ? 'asc' : (sort === 'info[rego] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[rego]')}>{__('Rego')}</h6>
                    <h6 className={sort === 'info[company] ASC' ? 'asc' : (sort === 'info[company] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[company]')}>{__('Company')}</h6>
                    <h6 className={sort === 'info[color] ASC' ? 'asc' : (sort === 'info[color] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[color]')}>{__('Colour')}</h6>
                    <h6 className={sort === 'info[truck] ASC' ? 'asc' : (sort === 'info[truck] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[truck]')}>{__('Deliver Truck')}</h6>
                    <h6></h6>
                </div>
                {data.map((inventory, i) => (
                    <section key={'i-' + i} className={'inventory' + (!inventory.id || (inventory.status || 0) > 0 ? '' : ' disabled')}>
                        <div title={__('Model')}>
                            <select name={'info[model][' + i + ']'} value={inventory.info?.model || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange}>
                                <option value=""></option>
                                {Models.map(model => (<option key={model} value={model}>{__(model)}</option>))}
                            </select>
                        </div>
                        <div title={__('VIN')}><input name={'sku[' + i + ']'} value={inventory.sku || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} /></div>
                        <div title={__('Stock in')}>
                            <div className="format-datetime-picker">
                                <FormatDateTimePicker name={'created_at[' + i + ']'} value={inventory.created_at || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} />
                            </div>
                        </div>
                        <div title={__('Rego')}><input name={'info[rego][' + i + ']'} value={inventory.info?.rego || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} /></div>
                        <div title={__('Company')}><input name={'info[company][' + i + ']'} value={inventory.info?.company || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} /></div>
                        <div title={__('Colour')}>
                            <select name={'info[color][' + i + ']'} value={inventory.info?.color || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange}>
                                <option value=""></option>
                                {Colors.map(color => (<option key={color} value={color}>{__(color)}</option>))}
                            </select>
                        </div>
                        <div title={__('Deliver Truck')}><input name={'info[truck][' + i + ']'} value={inventory.info?.truck || ''} disabled={Boolean(inventory.id) && (inventory.status || 0) <= 0} onChange={onChange} /></div>
                        <div title="">
                            {inventory.id ? null : (<MDBBtn type="button" color="secondary" size="sm" onClick={clear.bind(null, i)}>{__('Clear Row')}</MDBBtn>)}
                        </div>
                    </section>
                ))}
            </div>
            <div className="btns justify-content-between mt-3 py-2 px-2 px-lg-0 bg-white">
                <label>
                    <span>{__('Sort by')}</span>
                    <select onChange={e => onSort(e.target.value, e)} value={sort}>
                        <option value=""></option>
                        <option value="created_at DESC">{__('Latest to Oldest')}</option>
                        <option value="created_at ASC">{__('Oldest to Latest')}</option>
                        <option value="info[model] ASC">{__('Model')}</option>
                    </select>
                </label>
                <div>
                    <MDBBtn type="button" color="secondary" onClick={more}>{__('Add More Rows')}</MDBBtn>
                    <MDBBtn type="button" onClick={save}>{__('Save')}</MDBBtn>
                </div>
                <MDBDropdown dropup>
                    <MDBDropdownToggle><span className="fa fa-filter" /></MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (259200 === filter ? ' active' : '')} onClick={setFilter.bind(null, 259200)}>{__('In %d Days').replace('%d', '3')}</MDBBtn>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (604800 === filter ? ' active' : '')} onClick={setFilter.bind(null, 604800)}>{__('In %d Days').replace('%d', '7')}</MDBBtn>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (2592000 === filter ? ' active' : '')} onClick={setFilter.bind(null, 2592000)}>{__('In %d Days').replace('%d', '30')}</MDBBtn>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (0 === filter ? ' active' : '')} onClick={setFilter.bind(null, 0)}>{__('All')}</MDBBtn>
                        </MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
        </>
    );
}

function Stock(props: { warehouseId: number }) {
    let [inventories, setInventories] = React.useState<Array<InventoryData>>();
    let [sort, setSort] = React.useState('created_at DESC');
    let [version] = React.useContext(VersionContext);
    let [search] = React.useContext(SearchContext);
    let [__, lang] = useLocale();
    let onSort = React.useCallback((key: string, e: React.MouseEvent | React.ChangeEvent) => {
        if (!key) {
            return;
        }
        if (key.includes(' ')) {
            setSort(key);
        } else if (sort.startsWith(key)) {
            setSort(key + ' ' + (sort.endsWith('ASC') ? 'DESC' : 'ASC'));
        } else {
            setSort(key + ' DESC');
        }
    }, [sort]);
    React.useEffect(() => {
        let key = props.warehouseId + sort + 'status=1q:' + (search[props.warehouseId] || '');
        let cache = sessionStorage.getItem(key);
        if (cache) {
            let result = JSON.parse(cache);
            setInventories(result.data);
            if (result.data.length === 0 && search[props.warehouseId]) {
                Toast.show(__('No Data Matching %s').replace('%s', search[props.warehouseId]));
            }
        } else {
            getInventory({
                filter: { status: 1, warehouse_id: props.warehouseId, q: search[props.warehouseId] || '' },
                sort: 'main.' + sort, limit: 100
            }).then(response => {
                if (typeof response !== 'string') {
                    setInventories(response.data);
                    sessionStorage.setItem(key, JSON.stringify(response));
                    if (response.data.length === 0 && search[props.warehouseId]) {
                        Toast.show(__('No Data Matching %s').replace('%s', search[props.warehouseId]));
                    }
                }
            });
        }
    }, [props.warehouseId, sort, version, search]);
    return typeof inventories === 'undefined' ? (
        <div className="loading w100 text-center p-3">
            <span className="fa fa-spin fa-spinner d-block fa-3x" />
        </div>
    ) : (inventories.length === 0 ? (
        <div className="empty w100 text-center">
            <span className="fa fa-table-cells-large d-block fa-4x mb-3" />
            <span>{__('No Data to Display')}</span>
        </div>
    ) : (
        <>
            <div className="d-flex flex-wrap gap-3 d-lg-table">
                <div className="inventory head cursor">
                    <h6 className={sort === 'info[model] ASC' ? 'asc' : (sort === 'info[model] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[model]')}>{__('Model')}</h6>
                    <h6 className={sort === 'sku ASC' ? 'asc' : (sort === 'sku DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'sku')} style={{ minWidth: '17ch' }}>{__('VIN')}</h6>
                    <h6 className={'text-center ' + (sort === 'created_at ASC' ? 'asc' : (sort === 'created_at DESC' ? 'desc' : undefined))} onClick={onSort.bind(null, 'created_at')}>{__('Stock in')}</h6>
                    <h6 className={sort === 'info[rego] ASC' ? 'asc' : (sort === 'info[rego] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[rego]')}>{__('Rego')}</h6>
                    <h6 className={sort === 'info[company] ASC' ? 'asc' : (sort === 'info[company] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[company]')}>{__('Company')}</h6>
                    <h6 className={sort === 'info[color] ASC' ? 'asc' : (sort === 'info[color] DESC' ? 'desc' : undefined)} onClick={onSort.bind(null, 'info[color]')}>{__('Colour')}</h6>
                </div>
                {inventories?.map(inventory => (
                    <section className="inventory" key={inventory.id + '-' + version}>
                        <div title={__('Model')}>{inventory.info?.model || ''}</div>
                        <div title={__('VIN')}>{inventory.sku || ''}</div>
                        <div title={__('Stock in')}>{format(inventory.created_at * 1000, lang)}</div>
                        <div title={__('Rego')}>{inventory.info?.rego || ''}</div>
                        <div title={__('Company')}>{inventory.info?.company || ''}</div>
                        <div title={__('Colour')}>{inventory.info?.color || ''}</div>
                    </section>
                ))}
            </div>
            <div className="btns justify-content-between mt-3 py-2 px-2 px-lg-0 bg-white">
                <label>
                    <span>{__('Sort by')}</span>
                    <select onChange={e => onSort(e.target.value, e)} value={sort}>
                        <option value=""></option>
                        <option value="created_at DESC">{__('Latest to Oldest')}</option>
                        <option value="created_at ASC">{__('Oldest to Latest')}</option>
                        <option value="info[model] ASC">{__('Model')}</option>
                    </select>
                </label>
            </div>
        </>
    ));
}

interface ChargeProps {
    warehouseId: number;
    maintenances?: Array<MaintenanceData>;
}

function Charge(props: ChargeProps) {
    let [inventories, setInventories] = React.useState<Array<InventoryData>>();
    let [pricing, setPricing] = React.useState<PricingData>({});
    let [filter, setFilter] = React.useState(604800);
    let [version, setVersion] = React.useContext(VersionContext);
    let [search] = React.useContext(SearchContext);
    let [__] = useLocale();
    const finished = React.useRef<FinishedRef>(null);
    const other = React.useRef<OtherJobsRef>(null);
    const confirm = React.useRef<ConfirmRef>(null);
    const checkout = React.useRef<CheckoutRef>(null);
    let [isGranted] = useRbac();
    let showConfirm = React.useCallback((i: number, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        confirm.current?.show(inventories![i]);
    }, [inventories]);
    let showBilling = React.useCallback((i: number, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        let inventory = inventories![i];
        let id = inventory.id;
        checkout.current?.show(id, inventory.status > 0 && inventory.finished_at ? () =>
            deliverInventory({ id }) : () => Toast.show(__('The vehicle has not delivered.'), 'info'));
    }, [inventories, __]);
    let showSettle = React.useCallback((i: number, e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        checkout.current?.show(inventories![i].id);
    }, [inventories]);
    let deliver = React.useCallback((inventory: InventoryData) => {
        finished.current?.show(finished_at => {
            deliverInventory({ id: inventory.id, finished_at }).then(() => {
                setVersion();
            });
        }, [inventory.created_at * 1000, -1]);
    }, []);
    let details = React.useCallback((inventory: InventoryData, editable: boolean) => {
        other.current?.show(inventory.id, inventory?.maintenances?.['6']?.details || [], editable);
    }, []);
    const selectAll = React.useRef((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        let check = (e.target as HTMLElement).querySelector('input[type=checkbox]') as HTMLInputElement;
        let table = check.parentElement;
        while (table && !table?.classList.contains('d-lg-table')) {
            table = table.parentElement;
        }
        if (check.checked) {
            check.checked = false;
            table?.querySelectorAll('.inventory:not(.head) input[type=checkbox]').forEach(box => {
                box.parentElement?.parentElement?.classList.remove('checked');
                box && ((box as HTMLInputElement).checked = false);
            });
        } else {
            check.checked = true;
            table?.querySelectorAll('.inventory:not(.head) input[type=checkbox]').forEach(box => {
                box.parentElement?.parentElement?.classList.add('checked');
                box && ((box as HTMLInputElement).checked = true);
            });
        }
    });
    React.useEffect(() => {
        let sortBy = 'finished_at DESC';
        let sort = 'main.status DESC, ' + sortBy + ', main.created_at DESC';
        let key = props.warehouseId + sort + filter + 'q:' + (search[props.warehouseId] || '');
        let cache = sessionStorage.getItem(key);
        if (cache) {
            let result = JSON.parse(cache);
            setInventories(result.data);
            setPricing(result.config);
            if (result.data.length === 0 && search[props.warehouseId]) {
                Toast.show(__('No Data Matching %s').replace('%s', search[props.warehouseId]));
            }
        } else {
            getInventory({
                //@ts-ignore
                filter: { warehouse_id: props.warehouseId, q: search[props.warehouseId] || '', ...(filter > 0 ? { finished_at: { gte: (new Date).getTime() / 1000 - filter } } : {}) },
                sort, limit: 100
            }).then(response => {
                if (typeof response !== 'string') {
                    setInventories(response.data);
                    setPricing(response.config);
                    sessionStorage.setItem(key, JSON.stringify(response));
                    if (response.data.length === 0 && search[props.warehouseId]) {
                        Toast.show(__('No Data Matching %s').replace('%s', search[props.warehouseId]));
                    }
                }
            });
        }
    }, [props.warehouseId, version, filter, search, setInventories, setPricing]);
    return typeof inventories === 'undefined' ? (
        <div className="loading w100 text-center p-3">
            <span className="fa fa-spin fa-spinner d-block fa-3x" />
        </div>
    ) : (inventories?.length === 0 ? (
        <div className="empty w100 text-center">
            <span className="fa fa-table-cells-large d-block fa-4x mb-3" />
            <span>{__('No Data to Display')}</span>
        </div>
    ) : (
        <>
            <div className="d-flex flex-wrap gap-3 d-lg-table">
                <div className="inventory head text-center">
                    {isGranted('inventoryCheck') ? (
                        <h6 className="sticky" onClick={selectAll.current}>
                            <input type="checkbox" />
                        </h6>
                    ) : null}
                    <h6>{__('Model')}</h6>
                    <h6 style={{ minWidth: '17ch' }}>{__('VIN')}</h6>
                    <h6>{__('Rego')}</h6>
                    <h6>{__('Company')}</h6>
                    <h6>{__('Stock in')}</h6>
                    <h6>{__('Stock out')}</h6>
                    <h6>{__('Duration')}</h6>
                    {props.maintenances?.map(maintenance => (
                        <h6 key={maintenance.id.toString()}>
                            {__(maintenance.name)}
                        </h6>
                    ))}
                    <h6>{__('Freight')}</h6>
                    <h6>{__('Storage')}</h6>
                    <h6>{__('Handover')}</h6>
                    <h6 className="sticky">{__('Total')}</h6>
                    {isGranted('inventoryCheck') ? (
                        <div className="sticky row-btns"><span /></div>
                    ) : null}
                </div>
                {structuredClone(inventories || []).map((inventory, i) => (
                    <ChargeRow maintenances={props.maintenances} pricing={pricing}
                        inventory={inventory} warehouseId={props.warehouseId}
                        key={'inventory-' + props.warehouseId + '-' + inventory.sku + i}
                        deliver={deliver.bind(null, inventory)}
                        details={details.bind(null, inventory)}
                        onApprove={showConfirm.bind(null, i)}
                        onBilling={showBilling.bind(null, i)}
                        onSettle={showSettle.bind(null, i)}
                    />
                ))}
            </div>
            <div className="btns justify-content-between mt-3 p-2 bg-white">
                <div />
                <MDBDropdown dropup>
                    <MDBDropdownToggle><span className="fa fa-filter" /></MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (259200 === filter ? ' active' : '')} onClick={setFilter.bind(null, 259200)}>{__('In %d Days').replace('%d', '3')}</MDBBtn>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (604800 === filter ? ' active' : '')} onClick={setFilter.bind(null, 604800)}>{__('In %d Days').replace('%d', '7')}</MDBBtn>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (2592000 === filter ? ' active' : '')} onClick={setFilter.bind(null, 2592000)}>{__('In %d Days').replace('%d', '30')}</MDBBtn>
                        </MDBDropdownItem>
                        <MDBDropdownItem>
                            <MDBBtn color="link" type="button" block className={'px-3 text-start' + (0 === filter ? ' active' : '')} onClick={setFilter.bind(null, 0)}>{__('All')}</MDBBtn>
                        </MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>
            </div>
            <Confirm ref={confirm} />
            <Checkout ref={checkout} />
            <ModalFinished ref={finished} />
            <OtherJobs ref={other} />
        </>
    ));
}

interface ChargeRowProps {
    inventory: InventoryData;
    pricing: PricingData;
    warehouseId: number;
    maintenances?: Array<MaintenanceData>;
    deliver: () => void;
    details: (editable: boolean) => void;
    onApprove?: (e: React.MouseEvent<HTMLElement>) => void;
    onBilling?: (e: React.MouseEvent<HTMLElement>) => void;
    onSettle?: (e: React.MouseEvent<HTMLElement>) => void;
}

function ChargeRow(props: ChargeRowProps) {
    let [inventory, setInventory] = React.useState<InventoryData>();
    let [_, setVersion] = React.useContext(VersionContext);
    let [isGranted] = useRbac();
    let [__, lang] = useLocale();
    let days = React.useMemo(() => {
        return Math[props.pricing?.algorithm || 'round']((new BigNumber(inventory?.time || 0)).div(props.pricing?.seconds || 1).toNumber());
    }, [inventory, props.pricing]);
    let storage = React.useMemo(() => {
        return (new BigNumber(props.pricing?.price || 0)).times(days).toFixed(2);
    }, [days, props.pricing]);
    let total = React.useMemo(() => {
        let pd = new BigNumber('0');
        for (let m in (inventory?.maintenances || {})) {
            pd = pd.plus(inventory?.maintenances?.[m]?.price || 0);
        }
        return pd.plus(inventory?.freight || 0).plus(storage)
            .plus(props.pricing?.handover || 0).toFixed(2);
    }, [inventory, props.pricing, storage]);
    let editable = React.useMemo(() =>
        isGranted(['warehouseEntry', 'warehouseDelivery']) &&
            (inventory?.status || 0) > (isGranted('inventoryCheck') ? -1 : 0) ? true : undefined
        , [isGranted, inventory])
    let doCheck = React.useRef((e: React.MouseEvent<HTMLDivElement>) => {
        let o = e.target as HTMLElement;
        if (o.tagName === 'INPUT' || o.tagName === 'SELECT' || o.contentEditable === 'true') {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        while (o && !o.classList.contains('inventory')) {
            o = o.parentElement as HTMLElement;
        }
        let box = o.querySelector('input[type="checkbox"]') as HTMLInputElement;
        if (o.classList.contains('checked')) {
            o.classList.remove('checked');
            box && (box.checked = false);
        } else {
            o.classList.add('checked');
            box && (box.checked = true);
        }
    });
    let onChange = React.useCallback((key: string, e: { target: HTMLDivElement | HTMLInputElement }) => {
        let value: number;
        if ((e.target as HTMLInputElement).type === 'datetime-local') {
            value = (new Date((e.target as HTMLInputElement).value)).getTime() / 1000;
        } else {
            value = parseFloat(e.target.innerText.trim());
        }
        let data: any = { ...(inventory || {}) };
        if (key.startsWith('mt-')) {
            key = key.substring(3);
            if (typeof data.maintenances === 'undefined' || Array.isArray(data.maintenances) && data.maintenances.length === 0) {
                data.maintenances = {};
            }
            if ((data.maintenances[key]?.price || 0).toString() === (value || 0).toString()) {
                return;
            }
            let qty;
            if (Number.isNaN(value)) {
                qty = 0;
                delete data.maintenances[key];
            } else {
                data.maintenances[key] = { price: qty = value, details: [] };
            }
            saveInventoryMaintenance({
                maintenance_id: parseInt(key),
                inventory_id: data.id, qty, details: [],
                status: 1, finished_at: 1
            }).then(setVersion);
        } else {
            if ((data[key] || 0).toString() === (value || 0).toString()) {
                return;
            }
            if (Number.isNaN(value)) {
                delete data[key];
            } else {
                data[key] = value;
            } console.log(data);
            saveInventory({ id: data.id, [key]: value }).then(setVersion);
        }
        setInventory(data);
    }, [inventory, setVersion]);
    React.useEffect(() => {
        let data = { ...props.inventory };
        let saved: Record<string, 1> = {};
        for (let history of (data.history || [])) {
            for (let key in history.modified) {
                if (!saved[key]) {
                    saved[key] = 1;
                    if (key.startsWith('mt-')) {
                        if (typeof data.maintenances === 'undefined' || Array.isArray(data.maintenances) && data.maintenances.length === 0) {
                            data.maintenances = {};
                        }
                        data.maintenances[key.substring(3)] = {
                            price: history.modified[key].qty,
                            details: history.modified[key].details || {}
                        };
                    } else if (key !== 'id') {
                        //@ts-ignore
                        data[key] = history.modified[key];
                    }
                }
            }
        }
        setInventory(data);
    }, [props.inventory]);
    return inventory ? (
        <section className={'inventory' + ((inventory.history?.length || 0) > 0 ? ' modified' : (inventory.status == 0 ? ' finished' : ''))} onClick={isGranted('inventoryCheck') && inventory.status <= 0 ? doCheck.current : undefined} data-id={inventory.id}>
            {isGranted('inventoryCheck') ? (
                <>
                    <div className="sticky" title={__('Check')}>
                        {inventory.status <= 0 ? (
                            <input type="checkbox" value={inventory.id} />
                        ) : null}
                    </div>
                </>
            ) : null}
            <div title={__('Model')}>{inventory.info?.model || ''}</div>
            <div title={__('VIN')}>{inventory.sku || ''}</div>
            <div title={__('Rego')}>{inventory.info?.rego || ''}</div>
            <div title={__('Company')}>{inventory.info?.company || ''}</div>
            <div title={__('Stock in')}>
                <div className="format-datetime-picker">
                    <FormatDateTimePicker disabled={!editable} value={inventory.created_at} onBlur={onChange.bind(null, 'created_at')} />
                </div>
            </div>
            <div title={__('Stock out')}>
                <div className="format-datetime-picker">
                    <FormatDateTimePicker disabled={!editable} value={inventory.finished_at} onBlur={onChange.bind(null, 'finished_at')} />
                </div>
            </div>
            <div title={__('Storage Days')} className="text-center">{days || ''}</div>
            {props.maintenances?.map(maintenance => maintenance.id == 6 ? (
                <div key={maintenance.id.toString()} title={__(maintenance.name)} className="text-center">
                    {inventory.maintenances?.[maintenance.id] ? parseFloat(inventory.maintenances[maintenance.id].price.toString()).toFixed(2) : ''}
                    <button type="button" className="details" onClick={props.details.bind(null, Boolean(editable))}><span className="fa fa-info" /></button>
                </div>
            ) : (
                <div key={maintenance.id?.toString()} title={__(maintenance.name)} className="text-center" contentEditable={editable} suppressContentEditableWarning onBlur={onChange.bind(null, 'mt-' + maintenance.id)}>
                    {inventory.maintenances?.[maintenance.id] ? parseFloat(inventory.maintenances[maintenance.id].price.toString()).toFixed(2) : ''}
                </div>
            ))}
            <div title={__('Freight')} className="text-center" contentEditable={editable} suppressContentEditableWarning onBlur={onChange.bind(null, 'freight')}>{parseFloat(inventory.freight?.toString() || (props.pricing?.freight?.toString() || '0')).toFixed(2)}</div>
            <div title={__('Storage')} className="text-center">{storage}</div>
            <div title={__('Handover')} className="text-center">{parseFloat(props.pricing?.handover?.toString() || '0').toFixed(2)}</div>
            <div title={__('Total')} className="text-center sticky">{total}</div>
            {isGranted('inventoryCheck') || isGranted('warehouseDelivery') ? (
                <div className="sticky row-btns">
                    <span>
                        {isGranted('warehouseDelivery') ? (
                            <MDBBtn onClick={props.onBilling} type="button" title={__('Billing')}>
                                B{inventory.status < 1 ? (
                                    <span className="fa fa-check" />
                                ) : null}
                            </MDBBtn>
                        ) : null}
                        {isGranted('inventoryCheck') ? (inventory.history?.length || 0) > 0 ? (
                            <MDBBtn onClick={props.onApprove} type="button" title={__('Approval')}>A</MDBBtn>
                        ) : (
                            <MDBBtn onClick={inventory.status == 0 ? props.onSettle : undefined} type="button" title={__('Settle')}>
                                S{inventory.status < 0 ? (
                                    <span className="fa fa-check" />
                                ) : null}
                            </MDBBtn>
                        ) : null}
                    </span>
                </div>
            ) : null}
        </section>
    ) : null;
}

interface FormatDateTimePickerProps {
    value: number | string | Date | null;
    disabled?: boolean;
    name?: string;
    onBlur?: (e: { target: HTMLInputElement }) => void;
    onChange?: React.ChangeEventHandler;
    min?: number | string | Date;
    max?: number | string | Date;
}

function FormatDateTimePicker(props: FormatDateTimePickerProps) {
    let [value, setValue] = React.useState(props.value);
    let [changed, setChanged] = React.useState(false);
    let [__, lang] = useLocale();
    const input = React.useRef<HTMLInputElement>(null);
    let onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setChanged(true);
        props.onChange ? props.onChange(e) : setValue(new Date(e.target.value));
    }, [setValue, setChanged, props.onChange]);
    const doConfirm = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setChanged(false);
        input.current && props.onBlur?.({ target: input.current });
    }, [setChanged, props.onBlur]);
    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    return (
        <>
            {props.disabled ? null : (
                <input type="datetime-local" name={props.name} ref={input}
                    min={typeof props.min === 'undefined' ? undefined : format(new Date(props.min), lang, 'T')}
                    max={props.max === -1 ? undefined : format(props.max ? new Date(props.max) : new Date, lang, 'T')}
                    value={value ? (format(typeof value === 'number' ? value * 1000 : (new Date(value)).getTime(), lang, 'T')) : undefined}
                    onChange={onChange}
                />
            )}
            {changed ? (
                <MDBBtn type="button" color="info" size="sm" className="changed" onClick={doConfirm}>{__('Confirm')}</MDBBtn>
            ) : null}
            <span title={value ? format(typeof value === 'number' ? value * 1000 : (new Date(value)).getTime(), lang) : undefined}>
                {value ? format(typeof value === 'number' ? value * 1000 : (new Date(value)).getTime(), lang, '', false) : ''}
            </span>
        </>
    );
}

interface CheckoutRef {
    show: (id: number, beforeLoad?: () => void) => void;
}

const Checkout = React.forwardRef<CheckoutRef>(function (_, ref) {
    let [data, setData] = React.useState<null | {
        warehouse: WarehouseData;
        data: InventoryData;
        bill: BillData;
    }>(null);
    let [__] = useLocale();
    let [_1, setVersion] = React.useContext(VersionContext);
    const afterClose = React.useRef<boolean>(false);
    const loading = React.useRef<LoadingRef>(null);
    let onSubmit = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        loading.current?.show(true);
        reviewInventory({ inventory: { id: data?.data.id, status: -1 } }).then(() => {
            loading.current?.hide();
            Toast.show(__('Data has been saved successfully.'));
            setVersion();
            setData(null);
        });
    }, [data]);
    let doDismiss = React.useCallback(() => {
        setData(null);
        afterClose.current && setVersion();
    }, [setData, setVersion]);
    React.useImperativeHandle(ref, () => ({
        show: async (id, beforeLoad) => {
            loading.current?.show(true);
            try {
                if (beforeLoad) {
                    afterClose.current = true;
                    await beforeLoad();
                } else {
                    afterClose.current = false;
                }
                let response = await getInventoryBill({ id });
                if (typeof response !== 'string') {
                    setData(response);
                }
                loading.current?.hide();
            } catch {
                loading.current?.hide();
            }
        }
    }), [setData]);
    return (
        <>
            <MDBModal open={Boolean(data)} onClose={doDismiss} tabIndex="-1">
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalBody className="p-2">
                            <Bill sku={undefined} {...data} />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn type="button" color="secondary" onClick={doDismiss}>{__('Cancel')}</MDBBtn>
                            {data?.data.status == 0 ? (
                                <MDBBtn type="button" onClick={onSubmit}>{__('Checkout')}</MDBBtn>
                            ) : null}
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            <Loading ref={loading} />
        </>
    );
});

interface FinishedRef {
    show: (fn: (time: number) => void, range: [number | undefined, number | undefined]) => void;
}

const ModalFinished = React.forwardRef<FinishedRef>(function (_, ref) {
    let [open, setOpen] = React.useState(false);
    let [value, setValue] = React.useState(new Date());
    let [range, setRange] = React.useState<[number | undefined, number | undefined]>([undefined, undefined]);
    let [__] = useLocale();
    const modal = React.useRef<HTMLDivElement>(null);
    const submit = React.useRef<(time: number) => void>();
    let onBlur = React.useCallback((e: { target: HTMLInputElement }) => {
        setValue(new Date(e.target.value));
    }, [setValue]);
    let doSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (modal.current?.querySelector('.btn.changed')) {
            Toast.show(__('There has been changed data to confirm. Please save again after confirmation.'), 'danger');
            return;
        }
        let data = new FormData(e.target as HTMLFormElement);
        let time = data.get('finished_at') as string;
        if (time) {
            submit.current?.((new Date(time)).getTime() / 1000);
        }
        setOpen(false);
    }, [setOpen]);
    React.useImperativeHandle(ref, () => ({
        show: (fn, range) => {
            submit.current = fn;
            setRange(range);
            setOpen(true);
        }
    }), [setOpen]);
    return (
        <MDBModal open={open} onClose={setOpen.bind(null, false)} tabIndex="-1" modalRef={modal}>
            <MDBModalDialog centered>
                <MDBModalContent tag="form" onSubmit={doSubmit}>
                    <MDBModalHeader>
                        <MDBModalTitle>{__('Time of Delivery')}</MDBModalTitle>
                    </MDBModalHeader>
                    <MDBModalBody className="p-2">
                        <div className="format-datetime-picker bordered p-2">
                            <FormatDateTimePicker value={value} onBlur={onBlur} name="finished_at" min={range[0]} max={range[1]} />
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn type="button" color="secondary" onClick={setOpen.bind(null, false)}>{__('Cancel')}</MDBBtn>
                        <MDBBtn type="submit">{__('Submit')}</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
});

interface OtherJobsRef {
    show: (id: number, details: Array<[string, number]>, editable: boolean) => void
}

const OPTIONS = ['Window Tint', 'Tow Bar', 'Battery Check'];

const OtherJobs = React.forwardRef<OtherJobsRef>((_, ref) => {
    let [id, setId] = React.useState(0);
    let [data, setData] = React.useState<Map<string, number>>(new Map);
    let [__] = useLocale();
    let [_1, setVersion] = React.useContext(VersionContext);
    let [editable, setEditable] = React.useState(false);
    let doSave = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        let qty = new BigNumber(0);
        let details: Array<[string, number]> = [];
        for (const [key, value] of data.entries()) {
            if (key.length && value > 0) {
                details.push([key, value]);
                qty = qty.plus(value);
            }
        }
        saveInventoryMaintenance({ maintenance_id: 6, inventory_id: id, qty: qty.toNumber(), details, status: 1, finished_at: 1 }).then(response => {
            Toast.show(response, 'success');
            setId(0);
            setVersion();
        });
    }, [setId, id, data, setVersion]);
    let addMore = React.useCallback(() => {
        let result = Array.from(data.entries());
        result.push([__('Item') + ' ' + result.length, 0], [__('Item') + ' ' + (result.length + 1), 0], [__('Item') + ' ' + (result.length + 2), 0]);
        setData(new Map(result));
    }, [__, data, setData]);
    let onChange = React.useCallback((key: string, e: React.ChangeEvent<HTMLInputElement> | string) => {
        let value = typeof e === 'string' ? e : e.target.value;
        data.set(key, parseFloat(value));
        setData(new Map(data.entries()));
    }, [data, setData]);
    React.useImperativeHandle(ref, () => ({
        show: (id, details, editable) => {
            setId(id);
            setEditable(editable);
            let data: typeof details = [], tmp = new Map(details);
            for (let o of OPTIONS) {
                data.push([o, tmp.get(o) || 0]);
            }
            for (const [k, v] of tmp.entries()) {
                if (!OPTIONS.includes(k)) {
                    data.push([k, v]);
                }
            }
            setData(new Map(data));
        }
    }), [setId]);
    return (
        <>
            <MDBModal open={Boolean(id)} onClose={setId.bind(null, 0)} tabIndex="-1">
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalBody className="m-0 table table-sm table-bordered" tag="table">
                            <tbody>
                                {Array.from(data.entries()).map(([key, value]) => (
                                    <tr key={'row-' + key}>
                                        <th>
                                            <MDBInput disabled={!editable} label={__('Item')} value={key || ''} />
                                        </th>
                                        <td>
                                            <MDBInput disabled={!editable} label={__('Price')} className="active" value={value} min="0" onChange={onChange.bind(null, key)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn type="button" color="info" onClick={setId.bind(null, 0)}>{__('Cancel')}</MDBBtn>
                            {editable ? (
                                <>
                                    <MDBBtn type="button" onClick={addMore}>{__('Add More Rows')}</MDBBtn>
                                    <MDBBtn type="button" onClick={doSave}>{__('Save')}</MDBBtn>
                                </>
                            ) : null}
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
});

interface ConfirmRef {
    show: (inventory: InventoryData) => void
}

const Confirm = React.forwardRef<ConfirmRef, { maintenances?: Array<MaintenanceData> }>((props, ref) => {
    let [data, setData] = React.useState<InventoryData>();
    let [approved, setApproved] = React.useState<Record<string | number, number>>({});
    let maintenances = React.useMemo(() => {
        let result: Record<string, LocaleString> = {};
        for (let i of (props.maintenances || [])) {
            result['mt-' + i.id] = i.name;
        }
        return result;
    }, [props.maintenances]);
    let [__, lang] = useLocale();
    let [_, setVersion] = React.useContext(VersionContext);
    let onClick = React.useCallback((key: string, id: number, e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        let cloned = { ...approved };
        if (cloned[key] === id) {
            delete cloned[key];
        } else {
            cloned[key] = id;
        }
        setApproved(cloned);
    }, [approved, setApproved]);
    const loading = React.useRef<LoadingRef>(null);
    React.useImperativeHandle(ref, () => ({
        show: setData
    }), [setData]);
    let doCancel = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (Object.keys(approved).length === 0 || window.confirm(__('Unsaved data will be lost.'))) {
            setData(undefined);
            setApproved({});
        }
    }, [approved, setData, setApproved, __]);
    let doSave = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (data) {
            let ps: Array<Promise<any>> = [];
            let inventory: Partial<InventoryData> = { id: data.id };
            let history: Array<number> = [];
            let histories: Record<number, ModifiedData> = {};
            for (let h of (data.history || [])) {
                histories[h.id] = h;
            }
            for (let key in approved) {
                if (approved[key] > 0) {
                    history.push(approved[key]);
                    if (key.startsWith('mt-')) {
                        ps.push(saveInventoryMaintenance({
                            maintenance_id: parseInt(key.substring(3)),
                            inventory_id: data.id,
                            status: 1, finished_at: 1,
                            ...histories[approved[key]].modified[key]
                        }));
                    } else {
                        inventory[key as keyof InventoryData] = histories[approved[key]].modified[key];
                    }
                }
            }
            loading.current?.show(true);
            ps.push(reviewInventory({ inventory, history: history.length ? history : undefined }));
            Promise.all(ps).then(() => {
                loading.current?.hide();
                Toast.show(__('Data has been saved successfully.'));
                setVersion();
                setData(undefined);
            }, loading.current?.hide);
        }
    }, [data, approved, setData, setApproved, setVersion, __]);
    return (
        <MDBModal open={Boolean(data)}>
            <Loading ref={loading} />
            <MDBModalDialog centered>
                <MDBModalContent className="approval">
                    {data?.history?.length ? (
                        <>
                            <MDBModalHeader>
                                <p className="m-0">{__('Click approve button to approve changes. Others will be rejected.')}</p>
                            </MDBModalHeader>
                            <MDBListGroup className="w-100">
                                {data.history.map(item => (
                                    <MDBListGroupItem key={item.id.toString()}>
                                        {Object.keys(item.modified).map(key => (
                                            <React.Fragment key={item.id + key}>
                                                <h6 className="author">{item.user}</h6>
                                                {key === 'id' ? null : (key.startsWith('mt-') ? (
                                                    <>
                                                        <h5 className="attr">{__(maintenances[key])}</h5>
                                                        <div className="value">
                                                            <span className="from">{parseFloat((data.maintenances?.[key.substring(3)]?.price || 0).toString())}</span>
                                                            <span className="to">{parseFloat(item.modified[key].qty.toString())}</span>
                                                        </div>
                                                    </>
                                                ) : (key.endsWith('_at') ? (
                                                    <>
                                                        <h5 className="attr">{__(key === 'created_at' ? 'Stock in' : 'Stock out')}</h5>
                                                        <div className="value">
                                                            <span className="from">{data?.[key as keyof InventoryData] ? format(data[key as keyof InventoryData] * 1000, lang) : '-'}</span>
                                                            <span className="to">{format(item.modified[key] * 1000, lang)}</span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h5 className="attr">{__(key[0].toUpperCase() + key.substring(1))}</h5>
                                                        <div className="value">
                                                            <span className="from">{parseFloat(data?.[key as keyof InventoryData]?.toString())}</span>
                                                            <span className="to">{parseFloat(item.modified[key].toString())}</span>
                                                        </div>
                                                    </>
                                                )))}
                                                <div className="handler">
                                                    <span>{__(approved[key] === item.id ? 'Approved' : 'Rejected')}</span>
                                                    <MDBBtn type="button" size="sm" color={approved[key] === item.id ? 'danger' : 'success'} onClick={onClick.bind(null, key, item.id)}>
                                                        <span className="fa fa-refresh" />
                                                    </MDBBtn>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </MDBListGroupItem>
                                ))}
                            </MDBListGroup>
                            <MDBModalFooter>
                                <MDBBtn type="button" color="secondary" onClick={doCancel}>{__('Cancel')}</MDBBtn>
                                <MDBBtn type="button" onClick={doSave}>{__(Object.keys(approved).length === 0 ? 'Reject All' : 'Save')}</MDBBtn>
                            </MDBModalFooter>
                        </>
                    ) : (
                        <div className="empty py-3 d-flex flex-column align-items-center justify-content-center">
                            <span>{__('The data is up-to-date.')}</span>
                            <div className="d-flex gap-3 mt-3">
                                <MDBBtn type="button" color="secondary" onClick={doCancel}>{__('Close')}</MDBBtn>
                            </div>
                        </div>
                    )}
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
});

function BindCar() {
    let [open, setOpen] = React.useState(false);
    let [_, setVersion] = React.useContext(VersionContext);
    let [__] = useLocale();
    const loading = React.useRef<LoadingRef>(null);
    let onSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(false);
        loading.current?.show(false);
        let sku = (new FormData(e.target as HTMLFormElement)).get('vin') as string;
        bindInventory({ sku }).then(() => {
            loading.current?.hide();
            setVersion();
        }, () => loading.current?.hide());
    }, [setVersion]);
    return (
        <>
            <Loading ref={loading} />
            <MDBBtn className="bind" type="button" onClick={setOpen.bind(null, true)} title={__('Bind Your Car')}>
                <span className="fa fa-car-side" />
            </MDBBtn>
            <MDBModal open={open} onClose={setOpen.bind(null, false)} tabIndex="-1">
                <MDBModalDialog centered>
                    <MDBModalContent tag="form" onSubmit={onSubmit}>
                        <MDBModalBody>
                            <MDBInput label={__('VIN')} required name="vin" />
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn type="button" color="secondary" onClick={setOpen.bind(null, false)}>{__('Close')}</MDBBtn>
                            <MDBBtn type="submit">{__('Bind')}</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}
