import React from "react";
import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import useLocale from "../util/i18n";

type Breadcrumb = string | { link: string, label: string };

const CrumbsContext = React.createContext<[Array<Breadcrumb>, (crumbs: Array<Breadcrumb>) => void]>([[], () => { }]);

export function CrumbsProvider(props: { children: JSX.Element }) {
    const [breadcrumbs, setBreadcrumbs] = React.useState<Array<Breadcrumb>>([]);
    return (
        <CrumbsContext.Provider value={[breadcrumbs, setBreadcrumbs]}>
            {props.children}
        </CrumbsContext.Provider>
    );
}

export function useCrumbs() {
    return React.useContext(CrumbsContext);
}

export default function Breadcrumbs(props: { className?: string }) {
    let [breadcrumbs] = useCrumbs();
    let [__] = useLocale();
    return (
        <MDBBreadcrumb className={'container ' + (props.className || '')}>
            {breadcrumbs.map((item, index) => (
                <MDBBreadcrumbItem key={'b' + index}>
                    {typeof item === 'string' || !item.link ? (
                        <span>{__(typeof item === 'string' ? item : item.label)}</span>
                    ) : (<Link to={item.link}>{__(item.label)}</Link>)}
                </MDBBreadcrumbItem>
            ))}
        </MDBBreadcrumb>
    );
}
