import request, { CancelablePromise, download } from "../util/client";
import { LocaleString } from "../util/i18n";
import { defaultListParams, defaultListResponse } from "./types";

export interface WarehouseData {
    id: number;
    user_id: number;
    title: LocaleString;
    address: string;
    phone: string;
    region: number;
    country: number;
    status: number;
    lat?: number;
    lng?: number;
}

export interface InventoryData {
    id: number;
    warehouse_id: number;
    sku: string;
    user_id: number;
    info: any;
    qty: number;
    status: number;
    created_at: number;
    finished_at: number | null;
    notify_at: number | null;
    freight?: number;
    time?: number;
    maintenances?: { [id: number | string]: { price: number, details: Array<[string, number]> } }
    history?: Array<ModifiedData>
}

export interface ModifiedData {
    id: number;
    inventory_id: number;
    user_id: number;
    user: string;
    modified: Record<string, any>;
    status: number;
    created_at: number;
}

export interface PricingData {
    algorithm?: 'round' | 'floor' | 'ceil';
    price?: number;
    seconds?: number;
    handover?: number;
    freight?: number;
}

export interface BillData {
    'Finished at': number;
    Freight: number;
    Time: number;
    Handover: number;
    Total: number;
    [maintenance: string]: number;
}

export interface MaintenanceData {
    id: number;
    warehouse_id: number;
    name: LocaleString;
    price: number;
    sort_order: number;
    status: number;
    finished_at: number;
}

export interface InventoryMaintenanceData extends MaintenanceData {
    qty: number;
    process: number;
}

export function getInventoryBill(params: { sku: string } | { id: number }) {
    return request<{
        warehouse: WarehouseData;
        data: InventoryData;
        bill: BillData;
    }>('inventory/bill', 'GET', params);
}

export function getWarehouse(params: defaultListParams<{ id?: number, user_id?: number }>) {
    return request<defaultListResponse<WarehouseData>>('warehouse', 'GET', params);
}

export function saveWarehouse(data: FormData) {
    return request<string>('warehouse', 'PATCH', data);
}

export function getInventory(params: defaultListParams<{ id?: number, warehouse_id?: number, sku?: string, user_id?: number, status?: number, q?: string }>) {
    return request<defaultListResponse<InventoryData> & { config: PricingData }>('inventory', 'GET', params);
}

export function saveInventory(params: FormData | Partial<InventoryData>) {
    return request<string>('inventory', 'PUT', params);
}

export function deliverInventory(params: { id: number, finished_at?: number }) {
    return request<BillData>('inventory', 'DELETE', params);
}

export function reviewInventory(params: { inventory: Partial<InventoryData>, history?: Array<number> }) {
    return request<string>('inventory', 'PATCH', params);
}

export function exportInventory(params: { id: number | Array<number> }) {
    return download('inventory/export', 'GET', params);
}

export function bindInventory(params: { sku: string }) {
    return request<string>('inventory', 'POST', params);
}

export function getMaintenance(params: defaultListParams<{ inventory_id?: number }>): CancelablePromise<defaultListResponse<InventoryMaintenanceData>>
export function getMaintenance(params: defaultListParams<{ id?: number, warehouse_id?: number }>): CancelablePromise<defaultListResponse<MaintenanceData>>
export function getMaintenance(params: defaultListParams<{ id?: number, warehouse_id?: number, inventory_id?: number }>) {
    return request('maintenance', 'GET', params);
}

export function saveMaintenance(params: Partial<MaintenanceData>) {
    return request<string>('maintenance', 'PUT', params);
}

export function delMaintenance(params: { id: number | Array<number> }) {
    return request<string>('maintenance', 'DELETE', params);
}

export function saveInventoryMaintenance(params: FormData | { maintenance_id: number, inventory_id: number, qty?: number, details?: Array<[string, number]>, status?: number, finished_at?: number }) {
    return request<string>('maintenance/inventory', 'PUT', params);
}
