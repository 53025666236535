import React from "react";

interface ToastState {
    text: string | null;
    level: string | null;
    position: 'top' | 'bottom' | 'center';
    status: boolean;
}

export default class Toast extends React.PureComponent<{}, ToastState> {

    static instance: Toast | null = null;
    static timeout: NodeJS.Timeout | null | number = null;
    static ref: React.RefObject<HTMLDivElement>;

    constructor(props: {}) {
        super(props);
        this.state = {
            text: '',
            level: null,
            position: 'bottom',
            status: false
        };
        Toast.ref = React.createRef<HTMLDivElement>();
        Toast.instance = this;
    }

    static show(text: string, level: 'success' | 'danger' | 'primary' | 'secondary' | 'warning' | 'info' | null = null, delay: number = 3, position: ToastState['position'] = 'bottom'): Promise<void> {
        return new Promise(resolve => {
            Toast.instance?.setState({ text, level, position, status: true });
            Toast.timeout && clearTimeout(Toast.timeout);
            Toast.timeout = setTimeout(() => {
                Toast.hide().then(resolve);
            }, delay * 1000);
        });
    }

    static hide(): Promise<void> {
        return new Promise(resolve => {
            Toast.timeout = null;
            Toast.ref.current?.addEventListener('transitionend', () => {
                Toast.instance?.setState({ text: '' });
                resolve();
            }, { once: true });
            Toast.instance?.setState({ status: false });
        });
    }

    protected getIcon(level: string | null) {
        let icon: string | null = level;
        if (level === 'success') {
            icon = 'circle-check';
        } else if (level === 'info') {
            icon = 'circle-info';
        } else if (level === 'warning') {
            icon = 'triangle-exclamation';
        } else if (level === 'danger') {
            icon = 'circle-xmark';
        }
        return level ? (
            <span className={'icon fa fa-' + icon} />
        ) : null;
    }

    render() {
        return this.state.text?.length ? (
            <div className={'toast fade text-' + (this.state.level || 'success') + ' ' + this.state.position + (this.state.status ? ' show' : '')} ref={Toast.ref}>
                {this.getIcon(this.state.level)}
                <span className="text">{this.state.text}</span>
            </div>
        ) : null;
    }

}
