import React from "react";
import { Helmet } from "react-helmet-async";
import Edit, { Columns } from "../../component/Edit";
import { deleteUser, getRole, getUser, saveUser, UserData } from "../../api/admin";
import Toast from "../../component/toast";
import { CancelablePromise } from "../../util/client";
import { useCrumbs } from "../../component/breadcrumbs";
import useLocale from "../../util/i18n";

export default function UserEdit() {
    let [__] = useLocale();
    let [_, setCrumbs] = useCrumbs();
    let columns = React.useMemo<Columns>(() => ({
        [__('General')]: [
            {
                name: 'id',
                type: 'hidden'
            },
            {
                name: 'role',
                label: __('Role'),
                type: 'select',
                emptyOption: __('Administrator'),
                load: () => new Promise((resolve, reject) => {
                    getRole({ limit: 100 }).then(response => {
                        if (typeof response === 'string') {
                            reject(response)
                        } else {
                            let options = [];
                            for (let i of (response.data || [])) {
                                options.push({ value: i.id.toString(), label: i.name });
                            }
                            resolve(options);
                        }
                    }, reject);
                })
            },
            {
                name: 'username',
                required: true,
                label: __('Username')
            },
            {
                name: 'email',
                type: 'email',
                label: __('Email Address')
            },
            {
                name: 'status',
                label: __('Status'),
                type: 'select',
                required: true,
                options: [
                    { value: '1', label: __('Enabled') },
                    { value: '0', label: __('Disabled') }
                ]
            }
        ]
    }), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        let p = getUser({ filter: { id: params.id }, limit: 1 });
        return new CancelablePromise<UserData>((resolve, reject) => p.then(response => {
            if (typeof response !== 'string' && response.data.length) {
                resolve(response.data[0]);
            } else {
                reject(response);
            }
        }), p.doCancel);
    });
    const doSave = React.useRef((data: FormData) => {
        saveUser(data).then(response => Toast.show(response, 'success'));
    });
    const doDelete = React.useRef((data: Record<string, any>) => {
        if (window.confirm(__('Are you sure to delete this?'))) {
            deleteUser(data.id).then(response => Toast.show(response, 'success'));
        }
    });
    React.useEffect(() => {
        setCrumbs([{ label: 'User Management', link: '/user' }, 'Edit']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Edit %s').replace('%s', __('User'))}</title>
            </Helmet>
            <Edit columns={columns} doLoad={doLoad.current} doSave={doSave.current} doDelete={doDelete.current} />
        </>
    );
}
