import React from 'react';
import env from '../env.json';
import useLocale from './i18n';
import { getConfig } from '../api/core';
import { usePayload } from './token';

export const CurrencyContext = React.createContext<[string, (currency: string) => void]>([env.DEFAULT_CURRENCY, () => { }]);

export function CurrencyProvider(props: { children: JSX.Element }) {
    let [currency, setCurrency] = React.useState(env.DEFAULT_CURRENCY);
    let [payload] = usePayload();
    React.useEffect(() => {
        if (payload?.admin) {
            getConfig('currency/base').then(response => {
                if (typeof response !== 'string' && response['currency/base']) {
                    setCurrency(response['currency/base']);
                }
            });
        }
    }, [payload]);
    return (
        <CurrencyContext.Provider value={[currency, setCurrency]}>
            {props.children}
        </CurrencyContext.Provider>
    );
}

export function formatCurrency(price: number, lang: string, currency: string) {
    return (new Intl.NumberFormat(lang, { style: 'currency', currency, roundingMode: 'floor' })).format(price);
}

export default function FormatCurrency(props: { children: number }) {
    let [_, lang] = useLocale();
    let [currency] = React.useContext(CurrencyContext);
    let formatter = React.useMemo(() => {
        //@ts-ignore
        return new Intl.NumberFormat(lang, { style: 'currency', currency, roundingMode: 'floor' });
    }, [lang, currency]);
    return (
        <>{formatter.format(props.children)}</>
    );
}