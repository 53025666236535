import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Grid, { Actions, Columns } from "../../component/Grid";
import useLocale from "../../util/i18n";
import { useCrumbs } from "../../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import { BillData, deliverInventory, getInventory, getInventoryBill, InventoryData, saveInventory } from "../../api/warehouse";
import Lightbox, { LightBoxRef } from "../../component/lightbox";
import Resource, { ResourceRef } from "../../component/resource";
import Toast from "../../component/toast";
import Bill from "../../component/bill";
import Maintenance from "../../component/maintenance";

export default function InventoryGrid() {
    let [query, setQuery] = useSearchParams();
    let [__, lang] = useLocale();
    let [bill, setBill] = React.useState<BillData | null>(null);
    let [resQuery, setResQuery] = React.useState(new window.URLSearchParams);
    let [maintenance, setMaintenance] = React.useState([0, 0]);
    let navigate = useNavigate();
    const resource = React.useRef<ResourceRef>(null);
    const lightbox = React.useRef<LightBoxRef>(null);
    let columns = React.useMemo<Columns>(() => ({
        id: {
            label: 'ID',
            className: 'w-1 text-center text-nowrap'
        },
        sku: {
            label: __('VIN'),
            filterable: 'filter[sku]',
            format: value => __(value)
        },
        info: {
            label: __('Information'),
            filterable: 'filter[q]',
            format: value => {
                let result: Array<string> = [];
                if (value?.title || value?.brand) {
                    result.push(value?.title || value?.brand);
                }
                value?.plate && result.push(value?.plate);
                return result.join(' ');
            }
        },
        status: {
            label: __('Status'),
            className: 'w-1 text-center text-nowrap',
            type: 'select',
            options: [
                { value: '1', label: __('Delivered') }, { value: '0', label: __('Parking') }
            ],
            format: (value: number) => value ? __('Delivered') : __('Parking')
        },
        created_at: {
            label: __('Date'),
            className: 'w-1 text-center text-nowrap',
            format: (value: number, item) => (
                <>
                    {Intl.DateTimeFormat(lang, { dateStyle: 'medium', timeStyle: 'short' }).format(value * 1000)}<br />
                    {item.finished_at ? Intl.DateTimeFormat(lang, { dateStyle: 'medium', timeStyle: 'short' }).format(item.finished_at * 1000) : __('Parking')}
                </>
            )
        }
    }), [__]);
    let actions = React.useMemo<Actions>(() => ([
        {
            label: __('Edit'),
            onClick: (item: InventoryData) => navigate('/inventory/edit?id=' + item.id)
        },
        {
            label: __('View Images'),
            condition: (item: Record<string, any>) => item.info?.images?.length > 0,
            onClick: (item: InventoryData) => lightbox.current?.show(item.info?.images, 0)
        },
        {
            label: __('Add Image'),
            condition: (item: Record<string, any>) => item.status == 1,
            onClick: (item: InventoryData, row) => resource.current?.show('image', (url) => {
                let info = item.info || {};
                info.images = [...(info.images || []), url];
                saveInventory({ id: item.id, info }).then(response => {
                    Toast.show(response);
                    row.redraw({ ...item, info });
                });
            })
        },
        {
            label: __('Maintain'),
            condition: (item: Record<string, any>) => item.status == 1,
            onClick: (item: InventoryData) => setMaintenance([item.id, item.warehouse_id])
        },
        {
            label: __('Deliver'),
            condition: (item: Record<string, any>) => item.status == 1,
            onClick: (item: InventoryData, row) => {
                deliverInventory({ id: item.id }).then(response => {
                    if (typeof response !== 'string') {
                        setBill(response);
                        row.redraw({ ...item, status: 1, finished_at: Math.floor((new Date).getTime() / 1000) });
                    }
                });
            }
        },
        {
            label: __('View Bill'),
            onClick: (item: InventoryData) => {
                getInventoryBill({ sku: item.sku }).then(response => {
                    if (typeof response !== 'string') {
                        setBill(response.bill);
                    }
                });
            }
        }
    ]), [__, setBill, setMaintenance]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        return getInventory(params);
    });
    let [_, setCrumbs] = useCrumbs();
    React.useEffect(() => {
        setCrumbs([{ label: 'Warehouse Management', link: '/warehouse' }, 'Inventory']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Inventory')}</title>
            </Helmet>
            <Grid newUrl={'/inventory/edit?warehouse_id=' + query.get('filter[warehouse_id]')} columns={columns} actions={actions} query={query} setQuery={setQuery} doLoad={doLoad.current} />
            <Lightbox ref={lightbox} />
            <Resource query={resQuery} setQuery={setResQuery} ref={resource} />
            <Maintenance inventoryId={maintenance[0]} warehouseId={maintenance[1]} dismiss={setMaintenance.bind(null, [0, 0])} />
            {bill ? (<Bill sku={undefined} bill={bill} className="dialog" onDismiss={setBill.bind(null, null)} />) : null}
        </>
    );
}
