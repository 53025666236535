import React from "react";
import { Helmet } from "react-helmet-async";
import Edit, { Columns } from "../../component/Edit";
import { changePassword, deleteUser, getMyProfile, getRole, getUser, saveUser, UserData } from "../../api/admin";
import Toast from "../../component/toast";
import { CancelablePromise } from "../../util/client";
import { useCrumbs } from "../../component/breadcrumbs";
import useLocale from "../../util/i18n";
import { usePayload } from "../../util/token";

export default function Me() {
    let [__] = useLocale();
    let [_, setCrumbs] = useCrumbs();
    let [payload, _1, logout] = usePayload();
    let columns = React.useMemo<Columns>(() => ({
        [__('General')]: [
            {
                name: 'username',
                required: true,
                label: __('Username')
            },
            {
                name: 'email',
                type: 'email',
                label: __('Email Address')
            },
            {
                name: 'status',
                label: __('Status'),
                type: 'select',
                required: true,
                options: [
                    { value: '1', label: __('Enabled') },
                    { value: '0', label: __('Disabled') }
                ]
            }
        ],
        [__('Password')]: [
            {
                name: 'password',
                type: 'password',
                label: __('Password'),
                minLength: 6
            },
            {
                name: 'rpassword',
                type: 'password',
                label: __('Repeat Password'),
                minLength: 6
            },
        ]
    }), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        let p = getMyProfile();
        return new CancelablePromise<UserData>((resolve, reject) => p.then(response => {
            if (typeof response !== 'string') {
                resolve(response);
            } else {
                reject(response);
            }
        }), p.doCancel);
    });
    const doSave = React.useCallback(async (data: FormData) => {
        data.set('id', payload!.admin.toString());
        let message: string, final = () => { };
        try {
            message = await saveUser(data);
            if (data.get('password')) {
                if (data.get('password') !== data.get('rpassword')) {
                    Toast.show(__('The entered passwords are different.'), 'danger');
                    return;
                }
                await changePassword({ password: data.get('password') as string });
                message = __('The password has been reset. Please login again.');
                final = logout;
            }
            Toast.show(message, 'success').then(final);
        } catch (error: any) {
            Toast.show(error, 'danger');
        }
    }, [payload, __, logout]);
    React.useEffect(() => {
        setCrumbs(['My Profile']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('My Profile')}</title>
            </Helmet>
            <Edit columns={columns} doLoad={doLoad.current} doSave={doSave} />
        </>
    );
}
