import React from "react";
import Edit, { Columns } from "../../component/Edit";
import useLocale from "../../util/i18n";
import Toast from "../../component/toast";
import { useCrumbs } from "../../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import { CancelablePromise } from "../../util/client";
import { getInventory, saveInventory, InventoryData } from "../../api/warehouse";
import { useSearchParams } from "react-router-dom";

export default function InventoryEdit() {
    let [search] = useSearchParams();
    let [__] = useLocale();
    let [_, setCrumbs] = useCrumbs();
    let columns = React.useMemo<Columns>(() => ({
        [__('General')]: [
            {
                name: 'id',
                type: 'hidden'
            },
            {
                name: 'sku',
                label: __('VIN'),
                required: true
            },
            {
                name: 'info[title]',
                label: __('Title')
            },
            {
                name: 'info[brand]',
                label: __('Brand')
            },
            {
                name: 'info[plate]',
                label: __('Plate Number')
            },
            {
                name: 'info[images]',
                label: __('Images'),
                type: 'images'
            },
            {
                name: 'status',
                label: __('Status'),
                type: 'select',
                required: true,
                disabled: true,
                options: [
                    { value: '0', label: __('Parking') },
                    { value: '1', label: __('Delivered') }
                ]
            }
        ]
    }), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        let p = getInventory({ filter: { id: params.id }, limit: 1 });
        return new CancelablePromise<InventoryData>((resolve, reject) => p.then(response => {
            if (typeof response !== 'string' && response.data.length) {
                resolve(response.data[0]);
            } else {
                reject(response);
            }
        }), p.doCancel);
    });
    const doSave = React.useCallback((data: FormData) => {
        let wid = search.get('warehouse_id');
        wid && data.set('warehouse_id', wid);
        saveInventory(data).then(response => Toast.show(response, 'success'));
    }, [search]);
    React.useEffect(() => {
        setCrumbs([
            { label: 'Warehouse Management', link: '/warehouse' },
            { label: 'Inventory', link: '/inventory?filter%2Bwarehouse_id%2D=' + search.get('warehouse_id') }, 'Edit'
        ]);
    }, [search]);
    return (
        <>
            <Helmet>
                <title>{__('Inventory')}</title>
            </Helmet>
            <Edit columns={columns} doLoad={search.get('id') ? doLoad.current : undefined} doSave={doSave} />
        </>
    );
}
