import React from 'react';
import { MDBBtn, MDBPagination, MDBPaginationItem } from 'mdb-react-ui-kit';

export default function Pager(props: { total: number, limit?: number, search: URLSearchParams, onPage: (search: URLSearchParams) => void }) {
    let page = React.useMemo(() => parseInt(props.search.get('page') || '1'), [props.search]);
    let limit = React.useMemo(() => props.limit || parseInt(props.search.get('limit') || '10'), [props.limit, props.search]);
    let last = React.useMemo(() => Math.ceil(props.total / limit), [props.total, limit]);
    let onClick = React.useCallback((page: number, limit: number) => {
        props.onPage(new URLSearchParams([['page', page.toString()], ['limit', limit.toString()]]));
    }, [props]);
    return props.total ? (
        <MDBPagination className="mb-0">
            {page <= 1 ? null : (
                <>
                    <MDBPaginationItem>
                        <MDBBtn type="button" color="link" className="page-link" onClick={onClick.bind(null, 1, limit)}>
                            <span className="fa fa-angle-double-left" />
                        </MDBBtn>
                    </MDBPaginationItem>
                    <MDBPaginationItem>
                        <MDBBtn type="button" color="link" className="page-link" onClick={onClick.bind(null, page - 1, limit)}>
                            <span className="fa fa-angle-left" />
                        </MDBBtn>
                    </MDBPaginationItem>
                </>
            )}
            {page > 2 ? (
                <MDBPaginationItem>
                    <MDBBtn type="button" color="link" className="page-link" onClick={onClick.bind(null, page - 2, limit)}>{page - 2}</MDBBtn>
                </MDBPaginationItem>
            ) : null}
            {page > 1 ? (
                <MDBPaginationItem>
                    <MDBBtn type="button" color="link" className="page-link" onClick={onClick.bind(null, page - 1, limit)}>{page - 1}</MDBBtn>
                </MDBPaginationItem>
            ) : null}
            <MDBPaginationItem active>
                <MDBBtn type="button" color="link" className="page-link" disabled>{page}</MDBBtn>
            </MDBPaginationItem>
            {page < last ? (
                <MDBPaginationItem>
                    <MDBBtn type="button" color="link" className="page-link" onClick={onClick.bind(null, page + 1, limit)}>{page + 1}</MDBBtn>
                </MDBPaginationItem>
            ) : null}
            {page < last - 1 ? (
                <MDBPaginationItem>
                    <MDBBtn type="button" color="link" className="page-link" onClick={onClick.bind(null, page + 2, limit)}>{page + 2}</MDBBtn>
                </MDBPaginationItem>
            ) : null}
            {page < last ? (
                <>
                    <MDBPaginationItem>
                        <MDBBtn type="button" color="link" className="page-link" onClick={onClick.bind(null, page + 1, limit)}>
                            <span className="fa fa-angle-right" />
                        </MDBBtn>
                    </MDBPaginationItem>
                    <MDBPaginationItem>
                        <MDBBtn type="button" color="link" className="page-link" onClick={onClick.bind(null, last, limit)}>
                            <span className="fa fa-angle-double-right" />
                        </MDBBtn>
                    </MDBPaginationItem>
                </>
            ) : null}
        </MDBPagination>
    ) : null;
}
