import React from "react";
import Edit, { Columns } from "../../component/Edit";
import useLocale from "../../util/i18n";
import Toast from "../../component/toast";
import { useCrumbs } from "../../component/breadcrumbs";
import { Helmet } from "react-helmet-async";
import { CancelablePromise } from "../../util/client";
import { getWarehouse, saveWarehouse, WarehouseData } from "../../api/warehouse";

export default function WarehouseEdit() {
    let [__] = useLocale();
    let [_, setCrumbs] = useCrumbs();
    let columns = React.useMemo<Columns>(() => ({
        [__('General')]: [
            {
                name: 'id',
                type: 'hidden'
            },
            {
                name: 'title',
                label: __('Title'),
                type: 'locale',
                required: true
            },
            {
                name: 'phone',
                label: __('Phone')
            },
            {
                name: 'address',
                label: __('Address')
            },
            {
                name: 'lat',
                label: __('Latitude'),
                type: 'number',
                max: 180,
                min: -180,
                step: 0.000000001
            },
            {
                name: 'lng',
                label: __('Longitude'),
                type: 'number',
                max: 180,
                min: -180,
                step: 0.000000001
            },
            {
                name: 'status',
                label: __('Status'),
                type: 'select',
                required: true,
                options: [
                    { value: '1', label: __('Enabled') },
                    { value: '0', label: __('Disabled') }
                ]
            }
        ]
    }), [__]);
    const doLoad = React.useRef((params: Record<string, any>) => {
        let p = getWarehouse({ filter: { id: params.id }, limit: 1 });
        return new CancelablePromise<WarehouseData>((resolve, reject) => p.then(response => {
            if (typeof response !== 'string' && response.data.length) {
                resolve(response.data[0]);
            } else {
                reject(response);
            }
        }), p.doCancel);
    });
    const doSave = React.useRef((data: FormData) => {
        saveWarehouse(data).then(response => Toast.show(response, 'success'));
    });
    React.useEffect(() => {
        setCrumbs([{ label: 'Warehouse Management', link: '/warehouse' }, 'Edit']);
    }, []);
    return (
        <>
            <Helmet>
                <title>{__('Edit %s').replace('%s', __('Warehouse'))}</title>
            </Helmet>
            <Edit columns={columns} doLoad={doLoad.current} doSave={doSave.current} />
        </>
    );
}
